import React from 'react'
import { Link } from 'react-router-dom';

export default function DiscoverItems(props) {
    const { title, data } = props;

    return (
        <>
            <div className="container">
                <div className="grid grid-cols-1 text-center">
                    <h3 className="md:text-[30px] text-[26px] font-semibold">{title}</h3>
                </div>

                <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-10 gap-[30px]">
                    {data.map((item, index) => (
                        <div key={index} className="group relative overflow-hidden p-2 rounded-lg bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700 transition-all duration-500 hover:-mt-2 h-fit">
                            <div className="relative overflow-hidden">
                                <div className="relative overflow-hidden rounded-lg">
                                    <img src={item.image} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />
                                </div>

                                <div className="absolute -bottom-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-center transition-all duration-500">
                                    <Link to="/item-detail" className="btn btn-sm rounded-full bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white"><i className="mdi mdi-lightning-bolt"></i> Buy Now</Link>
                                </div>

                                <div className="absolute top-2 end-2 opacity-0 group-hover:opacity-100 transition-all duration-500">
                                    <Link to="#" className="btn btn-icon btn-sm rounded-full bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white"><i className="mdi mdi-plus"></i></Link>
                                </div>
                            </div>

                            <div className="mt-3">
                                <div className="flex items-center">
                                    <img src={item.avatar} className="rounded-full h-8 w-8" alt="" />
                                    <Link to="/creator-profile" className="ms-2 text-[15px] font-medium text-slate-400 hover:text-yellow-500">{item.subtext}</Link>
                                </div>

                                <div className="my-3">
                                    <Link to="/item-detail" className="font-semibold hover:text-yellow-500">{item.title}</Link>
                                </div>

                                <div className="flex justify-between p-2 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700">
                                    <div>
                                        <span className="text-[16px] font-medium text-slate-400 block">Price</span>
                                        <span className="text-[16px] font-semibold block"><i className="mdi mdi-ethereum"></i> {item.price || '3.5 ETH'}</span>
                                    </div>

                                    <div>
                                        <span className="text-[16px] font-medium text-slate-400 block">Highest Bid</span>
                                        <span className="text-[16px] font-semibold block"><i className="mdi mdi-ethereum"></i> {item.highestBid || '-'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {
                    data.length > 8 ? (
                        <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                            <div className="md:col-span-12 text-center">
                                <nav>
                                    <ul className="inline-flex items-center -space-x-px">
                                        <li>
                                            <Link to="/#" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500">
                                                <i className="uil uil-angle-left text-[20px]"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500">1</Link>
                                        </li>
                                        <li>
                                            <Link to="/#" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500">2</Link>
                                        </li>
                                        <li>
                                            <Link to="/#" aria-current="page" className="z-10 w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-white bg-yellow-500 shadow-sm dark:shadow-gray-700">3</Link>
                                        </li>
                                        <li>
                                            <Link to="/#" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500">4</Link>
                                        </li>
                                        <li>
                                            <Link to="/#" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500">
                                                <i className="uil uil-angle-right text-[20px]"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                    ) : ('')
                }

                {
                    title !== undefined ? (
                        <div className="grid grid-cols-1 mt-6">
                            <div className="text-center">
                                <Link to="/explore-one" className="btn btn-link text-[16px] font-medium hover:text-yellow-500 after:bg-yellow-500 duration-500 ease-in-out">Explore More <i className="uil uil-arrow-right"></i></Link>
                            </div>
                        </div>
                    ) : ('')
                }

            </div>
        </>
    )
}
