import React, { useEffect } from 'react'
import image from '../../assets/images/items/1.gif';
import image1 from '../../assets/images/items/1.jpg';
import image2 from '../../assets/images/items/2.jpg';
import image3 from '../../assets/images/items/3.jpg';
import image4 from '../../assets/images/items/2.gif';
import image5 from '../../assets/images/items/4.jpg';
import image6 from '../../assets/images/items/3.gif';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Switcher from '../../components/switcher';
import { Link } from 'react-router-dom';

export default function Activity() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Activities</h3>

                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">K-Sori</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Activity</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="md:flex">
                        <div className="lg:w-3/5 md:w-1/2 md:pe-4">
                            <div className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 overflow-hidden ease-in-out duration-500 w-full mx-auto lg:max-w-2xl">
                                <div className="lg:flex">
                                    <div className="relative md:shrink-0">
                                        <img className="h-full w-full object-cover lg:w-48" src={image} alt="" />
                                    </div>
                                    <div className="p-6 w-full">
                                        <ul className="flex justify-between items-center list-none pb-6">
                                            <li className="block items-center">
                                                <span className="bg-slate-900 text-white dark:bg-slate-800 text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">Listing</span>
                                                <span className="text-slate-400 text-sm ms-2">3min 50sec ago</span>
                                            </li>
                                            <li>
                                                <Link to="#" className="text-lg rounded-full text-gray-300 dark:text-gray-800 hover:text-red-600 focus:text-red-600 dark:hover:text-red-600 dark:focus:text-red-600"><i className="mdi mdi-heart"></i></Link>
                                            </li>
                                        </ul>

                                        <Link to="/item-detail" className="font-semibold">QUEEN'S COURT - 실버카드 에디션 #72/250 </Link>

                                        <div className="pt-6">
                                            <Link to="/item-detail" className="btn btn-sm rounded-full bg-yellow-500/5 hover:bg-yellow-500 border-yellow-500/10 hover:border-yellow-500 text-yellow-500 hover:text-white">$15에 구입하세요.</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 overflow-hidden ease-in-out duration-500 w-full mx-auto lg:max-w-2xl mt-[30px]">
                                <div className="lg:flex">
                                    <div className="relative md:shrink-0">
                                        <img className="h-full w-full object-cover lg:w-48" src={image1} alt="" />
                                    </div>
                                    <div className="p-6 w-full">
                                        <ul className="flex justify-between items-center list-none pb-6">
                                            <li className="block items-center">
                                                <span className="bg-slate-900 text-white dark:bg-slate-800 text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">Sale</span>
                                                <span className="text-slate-400 text-sm ms-2">3min 50sec ago</span>
                                            </li>
                                            <li>
                                                <Link to="#" className="text-lg rounded-full text-gray-300 dark:text-gray-800 hover:text-red-600 focus:text-red-600 dark:hover:text-red-600 dark:focus:text-red-600"><i className="mdi mdi-heart"></i></Link>
                                            </li>
                                        </ul>

                                        <Link to="/item-detail" className="font-semibold">블랙핑크 in K-sori #5001 9.27.에 판매되었어요. </Link>

                                        <div className="pt-6">
                                            <Link to="/item-detail" className="btn btn-sm rounded-full bg-yellow-500/5 hover:bg-yellow-500 border-yellow-500/10 hover:border-yellow-500 text-yellow-500 hover:text-white">구입하기: $15</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 overflow-hidden ease-in-out duration-500 w-full mx-auto lg:max-w-2xl mt-[30px]">
                                <div className="lg:flex">
                                    <div className="relative md:shrink-0">
                                        <img className="h-full w-full object-cover lg:w-48" src={image2} alt="" />
                                    </div>
                                    <div className="p-6 w-full">
                                        <ul className="flex justify-between items-center list-none pb-6">
                                            <li className="block items-center">
                                                <span className="bg-slate-900 text-white dark:bg-slate-800 text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">Offer</span>
                                                <span className="text-slate-400 text-sm ms-2">3min 50sec ago</span>
                                            </li>
                                            <li>
                                                <Link to="#" className="text-lg rounded-full text-gray-300 dark:text-gray-800 hover:text-red-600 focus:text-red-600 dark:hover:text-red-600 dark:focus:text-red-600"><i className="mdi mdi-heart"></i></Link>
                                            </li>
                                        </ul>

                                        <Link to="/item-detail" className="font-semibold"> K-sori 카리나 등장 신 $100.00 </Link>

                                        <div className="pt-6">
                                            <Link to="/item-detail" className="btn btn-sm rounded-full bg-yellow-500/5 hover:bg-yellow-500 border-yellow-500/10 hover:border-yellow-500 text-yellow-500 hover:text-white">구입하기:  $15</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 overflow-hidden ease-in-out duration-500 w-full mx-auto lg:max-w-2xl mt-[30px]">
                                <div className="lg:flex">
                                    <div className="relative md:shrink-0">
                                        <img className="h-full w-full object-cover lg:w-48" src={image3} alt="" />
                                    </div>
                                    <div className="p-6 w-full">
                                        <ul className="flex justify-between items-center list-none pb-6">
                                            <li className="block items-center">
                                                <span className="bg-slate-900 text-white dark:bg-slate-800 text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">Deposit</span>
                                                <span className="text-slate-400 text-sm ms-2">3min 50sec ago</span>
                                            </li>
                                            <li>
                                                <Link to="#" className="text-lg rounded-full text-gray-300 dark:text-gray-800 hover:text-red-600 focus:text-red-600 dark:hover:text-red-600 dark:focus:text-red-600"><i className="mdi mdi-heart"></i></Link>
                                            </li>
                                        </ul>

                                        <Link to="/item-detail" className="font-semibold">아이브 클럽 #1113 $32.87.에 판매되었어요.</Link>

                                        <div className="pt-6">
                                            <Link to="/item-detail" className="btn btn-sm rounded-full bg-yellow-500/5 hover:bg-yellow-500 border-yellow-500/10 hover:border-yellow-500 text-yellow-500 hover:text-white">구입하기:  $15</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 overflow-hidden ease-in-out duration-500 w-full mx-auto lg:max-w-2xl mt-[30px]">
                                <div className="lg:flex">
                                    <div className="relative md:shrink-0">
                                        <img className="h-full w-full object-cover lg:w-48" src={image4} alt="" />
                                    </div>
                                    <div className="p-6 w-full">
                                        <ul className="flex justify-between items-center list-none pb-6">
                                            <li className="block items-center">
                                                <span className="bg-slate-900 text-white dark:bg-slate-800 text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">Listing</span>
                                                <span className="text-slate-400 text-sm ms-2">3min 50sec ago</span>
                                            </li>
                                            <li>
                                                <Link to="#" className="text-lg rounded-full text-gray-300 dark:text-gray-800 hover:text-red-600 focus:text-red-600 dark:hover:text-red-600 dark:focus:text-red-600"><i className="mdi mdi-heart"></i></Link>
                                            </li>
                                        </ul>

                                        <Link to="/item-detail" className="font-semibold">Newjeans in K-sori #16/30 $600.00.에 판매되었어요. </Link>

                                        <div className="pt-6">
                                            <Link to="/item-detail" className="btn btn-sm rounded-full bg-yellow-500/5 hover:bg-yellow-500 border-yellow-500/10 hover:border-yellow-500 text-yellow-500 hover:text-white">구입하기:  $15</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 overflow-hidden ease-in-out duration-500 w-full mx-auto lg:max-w-2xl mt-[30px]">
                                <div className="lg:flex">
                                    <div className="relative md:shrink-0">
                                        <img className="h-full w-full object-cover lg:w-48" src={image5} alt="" />
                                    </div>
                                    <div className="p-6 w-full">
                                        <ul className="flex justify-between items-center list-none pb-6">
                                            <li className="block items-center">
                                                <span className="bg-slate-900 text-white dark:bg-slate-800 text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">Sale</span>
                                                <span className="text-slate-400 text-sm ms-2">3min 50sec ago</span>
                                            </li>
                                            <li>
                                                <Link to="#" className="text-lg rounded-full text-gray-300 dark:text-gray-800 hover:text-red-600 focus:text-red-600 dark:hover:text-red-600 dark:focus:text-red-600"><i className="mdi mdi-heart"></i></Link>
                                            </li>
                                        </ul>

                                        <Link to="/item-detail" className="font-semibold">Unsupervised — 2023 8월 K-sori 실황 콘서트 — I by Karina #84/100, $1,210.16.에 판매되었어요. </Link>

                                        <div className="pt-6">
                                            <Link to="/item-detail" className="btn btn-sm rounded-full bg-yellow-500/5 hover:bg-yellow-500 border-yellow-500/10 hover:border-yellow-500 text-yellow-500 hover:text-white">구입하기:  $15</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 overflow-hidden ease-in-out duration-500 w-full mx-auto lg:max-w-2xl mt-[30px]">
                                <div className="lg:flex">
                                    <div className="relative md:shrink-0">
                                        <img className="h-full w-full object-cover lg:w-48" src={image6} alt="" />
                                    </div>
                                    <div className="p-6 w-full">
                                        <ul className="flex justify-between items-center list-none pb-6">
                                            <li className="block items-center">
                                                <span className="bg-slate-900 text-white dark:bg-slate-800 text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">Listing</span>
                                                <span className="text-slate-400 text-sm ms-2">3min 50sec ago</span>
                                            </li>
                                            <li>
                                                <Link to="#" className="text-lg rounded-full text-gray-300 dark:text-gray-800 hover:text-red-600 focus:text-red-600 dark:hover:text-red-600 dark:focus:text-red-600"><i className="mdi mdi-heart"></i></Link>
                                            </li>
                                        </ul>

                                        <Link to="/item-detail" className="font-semibold">고백고백 콘서트 #7333 was put up for sale for $8.00. </Link>

                                        <div className="pt-6">
                                            <Link to="/item-detail" className="btn btn-sm rounded-full bg-yellow-500/5 hover:bg-yellow-500 border-yellow-500/10 hover:border-yellow-500 text-yellow-500 hover:text-white">구입하기:  $15</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="mt-[30px] text-center">
                                <Link to="#" className="text-yellow-500"><i className="mdi mdi-loading mdi-spin"></i> More Items</Link>
                            </div>
                        </div>

                        <div className="lg:w-2/5 md:w-1/2 md:ps-4">
                            <div className="sticky top-20">
                                <h5 className="text-lg font-semibold">검색 필터</h5>

                                <div className="form-icon relative mt-4">
                                    <i className="uil uil-search text-lg absolute top-1/2 -translate-y-1/2 start-3"></i>
                                    <input type="text" className="form-input w-full text-[15px] ps-10 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-yellow-500 focus:ring-0" name="s" id="searchItem2" placeholder="NFT 검색..." />
                                </div>

                                <h5 className="text-lg font-semibold mt-6">콜렉션 타입</h5>

                                <ul className="list-none mt-4">
                                    <li className="inline-block m-2"><Link to="#" className="px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-yellow-500 dark:hover:bg-yellow-500 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"><i className="uil uil-wallet me-1"></i> Purchased</Link></li>
                                    <li className="inline-block m-2"><Link to="#" className="px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-yellow-500 dark:hover:bg-yellow-500 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"><i className="uil uil-tag-alt me-1"></i> Sales</Link></li>
                                    <li className="inline-block m-2"><Link to="#" className="px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-yellow-500 dark:hover:bg-yellow-500 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"><i className="uil uil-fire me-1"></i> Burns</Link></li>
                                    <li className="inline-block m-2"><Link to="#" className="px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-yellow-500 dark:hover:bg-yellow-500 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"><i className="uil uil-heart me-1"></i> Likes</Link></li>
                                    <li className="inline-block m-2"><Link to="#" className="px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-yellow-500 dark:hover:bg-yellow-500 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"><i className="uil uil-browser me-1"></i> Bids</Link></li>
                                    <li className="inline-block m-2"><Link to="#" className="px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-yellow-500 dark:hover:bg-yellow-500 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"><i className="uil uil-users-alt me-1"></i> Following</Link></li>
                                    <li className="inline-block m-2"><Link to="#" className="px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-yellow-500 dark:hover:bg-yellow-500 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"><i className="uil uil-list-ui-alt me-1"></i> Listing</Link></li>
                                    <li className="inline-block m-2"><Link to="#" className="px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-yellow-500 dark:hover:bg-yellow-500 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"><i className="uil uil-music me-1"></i> Music</Link></li>
                                    <li className="inline-block m-2"><Link to="#" className="px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-yellow-500 dark:hover:bg-yellow-500 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"><i className="uil uil-camera me-1"></i> Video</Link></li>
                                    <li className="inline-block m-2"><Link to="#" className="px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-yellow-500 dark:hover:bg-yellow-500 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"><i className="uil uil-illustration me-1"></i> Illustration</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <Switcher />
        </>
    )
}
