import React, { useEffect } from 'react'
import Navbar from '../../components/navbar'
import image from '../../assets/images/items/1.jpg';
import image1 from '../../assets/images/avatar/1.jpg';
import image2 from '../../assets/images/items/2.gif';
import image3 from '../../assets/images/avatar/2.jpg';
import image4 from '../../assets/images/items/3.jpg';
import image5 from '../../assets/images/items/3.gif';
import TinySlider from "tiny-slider-react";
import Feature from '../../components/feature';
import CollectionTwo from '../../components/collection-two';
import AuctionsTwo from '../../components/auctions-two';
import CreatorTwo from '../../components/creator-two';
import item2 from '../../assets/images/items/2.gif';
import item5 from '../../assets/images/items/5.jpg';
import item6 from '../../assets/images/items/6.jpg';
import item7 from '../../assets/images/items/7.jpg';
import item9 from '../../assets/images/items/9.jpg';
import Footer from '../../components/footer';
import { Link } from 'react-router-dom';
import Switcher from '../../components/switcher';

const settings = {
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 12,
    responsive: {
        1025: {
            items: 5
        },

        992: {
            items: 4
        },

        767: {
            items: 3
        },

        425: {
            items: 1
        },
    },

}

export default function IndexSix() {
    useEffect(() => {
        document.documentElement.classList.remove('dark');
        document.documentElement.classList.add('light');
    });
    const discoverItems = [
        {
            image: image,
            subtext: '@StreetBoy',
            title: 'Genuine Undead #3902',
            avatar: image1
        },
        {
            image: image1,
            subtext: '@CutieGirl',
            title: 'Windchime #768',
            avatar: image2
        },
        {
            image: image2,
            subtext: '@버터시경',
            title: 'Probably A Label #3277',
            avatar: image3
        }, {
            image: image3,
            subtext: '@NorseQueen',
            title: 'Probably A Label #1711',
            avatar: image4
        }, {
            image: image4,
            subtext: '@BigBull',
            title: 'Shibuya Scramble Punks',
            avatar: image5
        },
        {
            image: image,
            subtext: '@StreetBoy',
            title: 'Genuine Undead #3902',
            avatar: image1
        },
        {
            image: image1,
            subtext: '@CutieGirl',
            title: 'Windchime #768',
            avatar: image2
        },
        {
            image: image2,
            subtext: '@버터시경',
            title: 'Probably A Label #3277',
            avatar: image3
        }
    ]
    const collectionData = [
        {
            image: image,
            title: 'Digital Collection',
            subtext: '@CutieGirl',
            items: 25,
            avatar: image
        },
        {
            image: image2,
            title: 'GIF Collection',
            subtext: '@버터시경',
            items: 25,
            avatar: image1
        },
        {
            image: image3,
            title: 'Sports Collection',
            subtext: '@NorseQueen',
            items: 25,
            avatar: image2
        },
        {
            image: image,
            title: 'Illustrations Collection',
            subtext: '@Angel',
            items: 25,
            avatar: image5
        },
        {
            image: image4,
            title: 'Animations Collection',
            subtext: '@CrazyAnyone',
            items: 25,
            avatar: image5
        },
        {
            image: image,
            title: 'Photography Collection',
            subtext: '@CrazyAnyone',
            items: 25,
            avatar: image2
        },
        {
            image: image2,
            title: 'Virtual Reality Collection',
            subtext: '@Princess',
            items: 25,
            avatar: image
        },
        {
            image: image,
            title: 'Cartoon Collection',
            subtext: '@LooserBad',
            items: 25,
            avatar: image4
        }
    ];

    const auctionData = [
        {
            image: image,
            title: 'Digital Collection',
            subtext: '@CutieGirl',
            items: 25,
            avatar: image,
            date: 'December 29, 2023 6:0:0'
        },
        {
            image: image2,
            title: 'GIF Collection',
            subtext: '@버터시경',
            items: 25,
            avatar: image1,
            date: 'April 20, 2024 1:6:3'
        },
        {
            image: image3,
            title: 'Sports Collection',
            subtext: '@NorseQueen',
            items: 25,
            avatar: image2,
            date: 'February 14, 2024 1:2:1'
        },
        {
            image: image,
            title: 'Illustrations Collection',
            subtext: '@Angel',
            items: 25,
            avatar: image5,
            date: 'April 20, 2024 1:6:3'
        },
    ]
    const data = [
        {
            avatar: image,
            name: "Steven Townsend",
            subtext: "@StreetBoy",
            subImages: [image, image1, image3]
        },
        {
            avatar: image1,
            name: "Tiffany Betancourt",
            subtext: "@CutieGirl",
            subImages: [image, item2, image4]
        },
        {
            avatar: image2,
            name: "Jacqueline Burns",
            subtext: "@버터시경",
            subImages: [image, item2, image2]

        },
        {
            avatar: image3,
            name: "Mari Harrington",
            subtext: "@NorseQueen",
            subImages: [item5, item6, image2]
        },
        {
            avatar: image4,
            name: "Floyd Glasgow",
            subtext: "@BigBull",
            subImages: [item7, image3, item9]

        },
        {
            avatar: image5,
            name: "Donna Schultz",
            subtext: "@Angel",
            subImages: [item7, image4, item9]

        }
    ];
    return (
        <>
            <Navbar />
            <section className="relative pt-24 overflow-hidden">
                <div className="container-fluid">
                    <div className="grid grid-cols-1 relative">
                        <div className="tiny-five-item">

                            <TinySlider settings={settings}>
                                {discoverItems.map((el, index) => (
                                    <div className="tiny-slide" key={index}>
                                        <div className="group relative overflow-hidden p-2 rounded-lg bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700 transition-all duration-500 h-fit m-2">
                                            <div className="relative overflow-hidden">
                                                <div className="relative overflow-hidden rounded-lg">
                                                    <img src={el.image} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />
                                                </div>

                                                <div className="absolute -bottom-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-center transition-all duration-500">
                                                    <Link to="/item-detail" className="btn btn-sm rounded-full bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white"><i className="mdi mdi-lightning-bolt"></i> Buy Now</Link>
                                                </div>

                                                <div className="absolute top-2 end-2 opacity-0 group-hover:opacity-100 transition-all duration-500">
                                                    <Link to="/" className="btn btn-icon btn-sm rounded-full bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white"><i className="mdi mdi-plus"></i></Link>
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                <div className="flex items-center">
                                                    <img src={el.avatar} className="rounded-full h-8 w-8" alt="" />
                                                    <Link to="/creator-profile" className="ms-2 text-[15px] font-medium text-slate-400 hover:text-yellow-500">{el.subtext}</Link>
                                                </div>

                                                <div className="my-3">
                                                    <Link to="/item-detail" className="font-semibold hover:text-yellow-500">{el.title}</Link>
                                                </div>

                                                <div className="flex justify-between p-2 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700">
                                                    <div>
                                                        <span className="text-[16px] font-medium text-slate-400 block">Price</span>
                                                        <span className="text-[16px] font-semibold block"><i className="mdi mdi-ethereum"></i> 3.5 ETH</span>
                                                    </div>

                                                    <div>
                                                        <span className="text-[16px] font-medium text-slate-400 block">Highest Bid</span>
                                                        <span className="text-[16px] font-semibold block"><i className="mdi mdi-ethereum"></i> 3.55 ETH</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ))}
                            </TinySlider>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <Feature />
                <CollectionTwo title="Top Collection" description="We are a huge marketplace dedicated to connecting great artists of all K-Sori with their fans and unique token collectors!" data={collectionData} />
                <AuctionsTwo title="Live Auctions"
                    description="We are a huge marketplace dedicated to connecting great artists of all K-Sori with their fans and unique token collectors!"
                    data={auctionData} />

                <CreatorTwo title="Creators & Seller" description="We are a huge marketplace dedicated to connecting great artists of all K-Sori with their fans and unique token collectors!"
                    data={data} />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
