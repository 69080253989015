import React from 'react'
import logo_white from '../assets/images/logo-white.png';
import app from '../assets/images/app.png';
import playstore from '../assets/images/playstore.png';
import { Link } from 'react-router-dom';
import { Mail, Phone } from 'react-feather';

export default function Footer() {
    return (
        <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200 mt-24">
            <div className="container">
                <div className="grid grid-cols-1">
                    <div className="relative py-16">
                        <div className="relative w-full">
                            <div className="relative -top-40 bg-white dark:bg-slate-900 lg:px-8 px-6 py-10 rounded-xl shadow dark:shadow-gray-800 overflow-hidden">
                                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                                    <div className="md:text-start text-center z-1">
                                        <h3 className="text-[26px] font-semibold text-slate-900 dark:text-white">K-Sori 뉴스레터 구독하기!</h3>
                                        <p className="text-slate-400 max-w-xl mx-auto">메타버스를 통한 뮤지션과 팬의 만남</p>
                                        <p className="text-slate-400 max-w-xl mx-auto">K-Sori의 최신 업데이트와 아티스트 정보를 놓치지 마세요.</p>                                        
                                    </div>

                                    <div className="subcribe-form z-1">
                                        <form className="relative max-w-lg md:ms-auto">
                                            <input type="email" id="subcribe" name="email" className="pt-4 pe-40 pb-4 ps-6 w-full h-[50px] outline-none text-slate-900 dark:text-white rounded-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800" placeholder="이메일 입력 :" />
                                            <button type="submit" className="btn absolute top-[2px] end-[3px] h-[46px] bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white rounded-full">구독</button>
                                        </form>
                                    </div>
                                </div>

                                <div className="absolute -top-5 -start-5">
                                    <div className="uil uil-envelope lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5 -rotate-45"></div>
                                </div>

                                <div className="absolute -bottom-5 -end-5">
                                    <div className="uil uil-pen lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5"></div>
                                </div>
                            </div>

                            <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] -mt-24">
                                <div className="lg:col-span-4 md:col-span-12">
                                    <Link to="#" className="text-[22px] focus:outline-none">
                                        <img src={logo_white} alt=""/>
                                    </Link>
                                    <p className="mt-6 text-gray-300">K-Sori의 최고 아티스트들의 독점 디지털 자산을 구매, 판매하고 발견하세요.</p>

                                </div>

                                <div className="lg:col-span-2 md:col-span-4">
                                    <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">K-Sori</h5>
                                    <ul className="list-none footer-list mt-6">
                                        <li><Link to="/explore-one" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b me-1"></i> NFT 탐색</Link></li>
                                        <li className="mt-[10px]"><Link to="/auction" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b me-1"></i> 라이브 경매</Link></li>
                                        <li className="mt-[10px]"><Link to="/activity" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b me-1"></i> 액티비티</Link></li>
                                        {/* <li className="mt-[10px]"><Link to="/wallet" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b me-1"></i> 지갑</Link></li> */}
                                        <li className="mt-[10px]"><Link to="/creators" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b me-1"></i> 창작자</Link></li>
                                    </ul>
                                </div>

                                {/* <div className="lg:col-span-3 md:col-span-4">
                                    <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">Usefull Links</h5>
                                    <ul className="list-none footer-list mt-6">
                                        <li><Link to="/aboutus" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b me-1"></i> About Us</Link></li>
                                        <li className="mt-[10px]"><Link to="/blogs" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b me-1"></i> Blog & News</Link></li>
                                        <li className="mt-[10px]"><Link to="/terms" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b me-1"></i> Terms & Condition</Link></li>
                                        <li className="mt-[10px]"><Link to="/privacy" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b me-1"></i> Privacy policy</Link></li>
                                        <li className="mt-[10px]"><Link to="/login" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b me-1"></i> Login</Link></li>
                                        <li className="mt-[10px]"><Link to="/contact" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b me-1"></i> Contact Us</Link></li>
                                    </ul>
                                </div> */}

                                <div className="lg:col-span-3 md:col-span-4">
                                    <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">K-Sori 메타버스 앱 다운로드</h5>

                                    <ul className="list-none mt-6">
                                        <li className="inline"><img src={app} className="h-9 inline-block" alt="" /></li>
                                        <li className="inline"><img src={playstore} className="h-9 inline-block" alt="" /></li>
                                    </ul>

                                    <div className="mt-6">
                                        <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">고객상담 연락처</h5>

                                        <div className="flex mt-6">
                                            <Mail className="w-5 h-5 text-yellow-500 me-3 mt-1"></Mail>
                                            <div className="">
                                                <Link to="mailto:contact@goback.world" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out">contact@goback.world</Link>
                                            </div>
                                        </div>

                                        <div className="flex mt-6">
                                            <Phone className="w-5 h-5 text-yellow-500 me-3 mt-1"></Phone>
                                            <div className="">
                                                <Link to="tel:+152534-468-854" className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out">+82 063-283-9101</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
                <div className="container text-center">
                    <div className="grid md:grid-cols-2 items-center gap-6">
                        <div className="md:text-start text-center">
                            <p className="mb-0 text-gray-300">© {(new Date().getFullYear())}{" "} K-Sori. Design & Develop with <i className="mdi mdi-heart text-red-600"></i> by <Link to="https://goback.world/" target="_blank" className="text-reset">(주)고백기술</Link>.</p>
                        </div>

                        {/* <ul className="list-none md:text-end text-center">
                            <li className="inline"><Link to="https://1.envato.market/K-Sori-react" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500"><i className="uil uil-shopping-cart align-middle" title="Buy Now"></i></Link></li>
                            <li className="inline"><Link to="https://dribbble.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500"><i className="uil uil-dribbble align-middle" title="dribbble"></i></Link></li>
                            <li className="inline"><Link to="https://www.behance.net/shreethemes" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500"><i className="uil uil-behance" title="Behance"></i></Link></li>
                            <li className="inline"><Link to="http://linkedin.com/company/shreethemes" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500"><i className="uil uil-linkedin" title="Linkedin"></i></Link></li>
                            <li className="inline"><Link to="https://www.facebook.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500"><i className="uil uil-facebook-f align-middle" title="facebook"></i></Link></li>
                            <li className="inline"><Link to="https://www.instagram.com/shreethemes/" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500"><i className="uil uil-instagram align-middle" title="instagram"></i></Link></li>
                            <li className="inline"><Link to="https://twitter.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500"><i className="uil uil-twitter align-middle" title="twitter"></i></Link></li>
                            <li className="inline"><Link to="mailto:support@shreethemes.in" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500"><i className="uil uil-envelope align-middle" title="email"></i></Link></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </footer>
    )
}
