import React, { useEffect } from 'react'
import Creator from '../../components/creator';
import Collections from '../../components/collections';

import image from '../../assets/images/avatar/1.jpg';
import image1 from '../../assets/images/avatar/2.jpg';
import image2 from '../../assets/images/items/3.gif';
import image3 from '../../assets/images/avatar/3.jpg';
import image4 from '../../assets/images/avatar/4.jpg';
import image5 from '../../assets/images/avatar/5.jpg';
import image6 from '../../assets/images/avatar/6.jpg';
import image7 from '../../assets/images/items/5.gif';
import image8 from '../../assets/images/avatar/7.jpg';

import item1 from '../../assets/images/items/1.jpg';
import item2 from '../../assets/images/items/2.gif';
import item3 from '../../assets/images/items/3.jpg';
import item4 from '../../assets/images/items/4.jpg';
import item5 from '../../assets/images/items/5.jpg';
import item6 from '../../assets/images/items/6.jpg';
import DiscoverItems from '../../components/discover-items';
import QA from '../../components/qa';
import GetTouch from '../../components/get-touch';
import Footer from '../../components/footer';
import Navbar from '../../components/navbar';
import { Link } from 'react-router-dom';
import Switcher from '../../components/switcher';

export default function IndexFour() {

    useEffect(() => {
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');

    }, []);

    const data = [
        {
            avatar: image,
            name: "Steven Townsend",
            subtext: "@StreetBoy"
        },
        {
            avatar: image1,
            name: "Tiffany Betancourt",
            subtext: "@CutieGirl"
        },
        {
            avatar: image2,
            name: "Jacqueline Burns",
            subtext: "@버터시경"
        },
        {
            avatar: image3,
            name: "Mari Harrington",
            subtext: "@NorseQueen"
        },
        {
            avatar: image4,
            name: "Floyd Glasgow",
            subtext: "@BigBull"
        },
        {
            avatar: image5,
            name: "Donna Schultz",
            subtext: "@Angel"
        },
        {
            avatar: image6,
            name: "Joshua Morris",
            subtext: "@CrazyAnyone"
        },
        {
            avatar: image7,
            name: "Rosaria Vargas",
            subtext: "@Princess"
        },
        {
            avatar: image8,
            name: "Carl Williams",
            subtext: "@LooserBad"
        }
    ];

    const collectionData = [
        {
            image: item1,
            title: 'Digital Collection',
            subtext: '@CutieGirl',
            items: 25,
            avatar: image
        },
        {
            image: item2,
            title: 'GIF Collection',
            subtext: '@버터시경',
            items: 25,
            avatar: image1
        },
        {
            image: item3,
            title: 'Sports Collection',
            subtext: '@NorseQueen',
            items: 25,
            avatar: image2
        },
        {
            image: item5,
            title: 'Illustrations Collection',
            subtext: '@Angel',
            items: 25,
            avatar: image5
        },
        {
            image: image6,
            title: 'Animations Collection',
            subtext: '@CrazyAnyone',
            items: 25,
            avatar: item6
        }
    ];

    const discoverItems = [
        {
            image: item1,
            subtext: '@StreetBoy',
            title: 'Genuine Undead #3902',
            avatar: image
        },
        {
            image: item2,
            subtext: '@CutieGirl',
            title: 'Windchime #768',
            avatar: image1
        },
        {
            image: image1,
            subtext: '@버터시경',
            title: 'Probably A Label #3277',
            avatar: image2
        }, {
            image: image3,
            subtext: '@NorseQueen',
            title: 'Probably A Label #1711',
            avatar: item3
        }, {
            image: image2,
            subtext: '@BigBull',
            title: 'Shibuya Scramble Punks',
            avatar: image4
        }, {
            image: item4,
            subtext: '@Angel',
            title: 'Probably A Label #650',
            avatar: image5
        }, {
            image: item5,
            subtext: '@CrazyAnyone',
            title: 'Looki#0147',
            avatar: image6
        }, {
            image: item6,
            subtext: '@Princess',
            title: 'Poob #285',
            avatar: image7
        },
    ]

    return (
        <>
            <span className="fixed blur-[200px] w-[600px] h-[600px] rounded-full top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/20 to-yellow-500/20 dark:from-red-600/40 dark:to-yellow-500/40"></span>
            <Navbar />
            <section className="relative md:pt-48 pt-36 overflow-hidden">
                <div className="container">
                    <div className="grid grid-cols-1 justify-center text-center mt-10">
                        <div className="relative">
                            <div className="relative mb-5">
                                <h1 class="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl">Discover <span class="bg-gradient-to-l from-red-600 to-yellow-500 text-transparent bg-clip-text">Digital <br /> Arts </span> &amp; <span class="bg-gradient-to-l from-red-600 to-yellow-500 text-transparent bg-clip-text">Collect NFTs</span></h1>

                                <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-yellow-500/10 dark:after:bg-yellow-500/30 after:-top-[50px] after:start-[30%] after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>

                                <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-yellow-500/20 dark:after:bg-yellow-500/40 after:bottom-[0] after:end-[15%] after:-z-1 after:rounded-full after:animate-ping"></div>
                            </div>
                            <p className="text-slate-400 dark:text-white/70 text-lg max-w-xl mx-auto">We are a huge marketplace dedicated to connecting great artists of all K-Sori with their fans and unique token collectors!</p>

                            <div className="mt-8">
                                <Link to="/explore-one" className="btn bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white rounded-full">Explore now</Link>
                            </div>
                        </div>
                    </div>

                    <div className="relative animate-[spin_30s_linear_infinite] -z-1">
                        <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:h-2 after:w-8 after:rounded-md after:bg-yellow-500/20 relative after:z-10"></span>
                        <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:rotate-90 after:h-2 after:w-8 after:rounded-md after:bg-yellow-500/20 relative after:z-10"></span>
                    </div>
                </div>
                <Collections data={collectionData} />

            </section>
            <section className="relative md:py-24 py-16">
                <DiscoverItems title='Discover Items' data={discoverItems} />
                <Creator title="Best Creators & Sellers" data={data}
                    description="We are a huge marketplace dedicated to connecting great artists of all K-Sori with their fans and unique token collectors!"
                />
                <QA />
                <GetTouch />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
