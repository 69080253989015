 // eslint-disable-next-line
import React, { useEffect } from 'react'
import TinySlider from "tiny-slider-react";
import { Link } from 'react-router-dom';


import image from '../assets/images/items/1.jpg';
import image1 from '../assets/images/avatar/1.jpg';
import image2 from '../assets/images/items/1.gif';
import image22 from '../assets/images/items/2.gif';
import image222 from '../assets/images/items/3.gif';
import gif2 from '../assets/images/items/10.gif';
import gif1 from '../assets/images/items/6.gif';
import image3 from '../assets/images/avatar/2.jpg';
import image4 from '../assets/images/items/3.jpg';
import image5 from '../assets/images/items/3.gif';
import image8 from '../assets/images/items/5.jpg';


import item1 from '../assets/images/blog/01.jpg';
import item2 from '../assets/images/blog/02.jpg';
import item3 from '../assets/images/blog/03.jpg';
import item4 from '../assets/images/blog/04.jpg';
import item5 from '../assets/images/blog/05.jpg';
import item6 from '../assets/images/blog/06.jpg';
import item7 from '../assets/images/blog/07.jpg';
import item8 from '../assets/images/items/5.gif';
import item9 from '../assets/images/items/7.gif';
import item10 from '../assets/images/blog/13.jpg';
import item11 from '../assets/images/blog/14.jpg';
import item12 from '../assets/images/blog/05.jpg';


import Navbar from '../components/navbar'
import CreatorThree from '../components/creator-three';
import Feature from '../components/feature';
import Blog from '../components/blog';
import GetTouch from '../components/get-touch';
import Footer from '../components/footer';
import ExploreTtem from '../components/explore-item';
import Switcher from '../components/switcher';

const settings = {
    items: 1,
    controls: true,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    nav: false,
    speed: 400,
    gutter: 0,
    controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>']
};
export default function Index() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    });
    const data = [
        {
            avatar: image,
            name: "Ive",
            subtext: "@Ive",
            subImages: [gif1, image1, image3]
        },
        {
            avatar: image1,
            name: "Newjeans",
            subtext: "@NJ",
            subImages: [image, item2, image4]
        },
        {
            avatar: image2,
            name: "Tiffany",
            subtext: "@Tif",
            subImages: [image, item2, image2]
        },
        {
            avatar: image3,
            name: "Blackpink",
            subtext: "@blackpink",
            subImages: [item5, item6, image2]
        },
        {
            avatar: image4,
            name: "gidle",
            subtext: "@idle",
            subImages: [item7, image8, item9]
        },
        {
            avatar: image5,
            name: "BOL4",
            subtext: "@bol4",
            subImages: [item7, image8, item9]
        },
        {
            avatar: item1,
            name: "codeshift",
            subtext: "@codeshift",
            subImages: [gif2, image1, image3]
        },
        {
            avatar: item3,
            name: "goback",
            subtext: "@goback",
            subImages: [image, item2, image4]
        },
        {
            avatar: image2,
            name: "Nenene",
            subtext: "@nene",
            subImages: [image, item2, image2]
        },
        {
            avatar: image3,
            name: "Nana",
            subtext: "@nana",
            subImages: [item5, item6, image2]
        },
        {
            avatar: item6,
            name: "nono",
            subtext: "@Nono",
            subImages: [item7, image8, item9]
        },
        {
            avatar: image5,
            name: "Suppp",
            subtext: "@sss",
            subImages: [item7, image8, item9]
        },
    ];

    const discoverItems = [
        {
            image: item7,
            subtext: '@StreetBoy',
            title: '새로운 업데이트 #3902',
            avatar: image1
        },
        {
            image: item8,
            subtext: '@CutieGirl',
            title: 'Queen Karina #768',
            avatar: image22
        },
        {
            image: item9,
            subtext: '@버터시경',
            title: 'Karina2 #3277',
            avatar: image22
        }, {
            image: item10,
            subtext: '@NorseQueen',
            title: 'Karian3 #1711',
            avatar: image4
        }, {
            image: item11,
            subtext: '@BigBull',
            title: 'Ive가 최고지',
            avatar: image5
        },
        {
            image: image,
            subtext: '@StreetBoy',
            title: '글로벌하면 블핑 #3902',
            avatar: image1
        },
        {
            image: item12,
            subtext: '@CutieGirl',
            title: '코첼라!!!의 감동 #768',
            avatar: item12
        },
        {
            image: image222,
            subtext: '@버터시경',
            title: '날자 #3277',
            avatar: image3
        }
    ]
    return (
        <>
            <Navbar />
            <section className="relative lg:pt-24 pt-[74px] overflow-hidden">
                <div className="container-fluid lg:px-10 md:px-3 relative overflow-hidden">
                    <span className="absolute blur-[200px] w-[600px] h-[600px] rounded-full top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/40 to-yellow-500/40 dark:from-red-600/60 dark:to-yellow-500/60"></span>
                    <div className="lg:py-24 py-[74px] md:rounded-lg shadow dark:shadow-gray-800 bg-yellow-600/10 dark:bg-yellow-500/20">
                        <div className="container">
                            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                                <div className="md:col-span-7">
                                    <div className="md:me-6">
                                        <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span className="bg-gradient-to-l from-red-600 to-yellow-500 text-transparent bg-clip-text">K-Sori 메타버스</span>    그리고 <br /> 뮤지션들의 NFT</h4>
                                        <p className="text-lg max-w-xl">인디 뮤지션과 관객이 만나는 공간, K-Sori</p>
                                        <p className="text-lg max-w-xl">당신의 원픽을 찾았다면 팬덤 활동은 이곳이죠.</p>                                        
                                        <p className="text-lg max-w-xl">여기서 NFT를 구매하고, 판매하고, 전시하세요.</p>
                                        <br></br>
                                        <p className="text-lg max-w-xl">K-Sori 인디뮤지션 메타버스는 뮤지션과 음악애호가의 접점을 만듭니다.</p>

                                        <div className="mt-6">
                                            <Link to="/explore-two" className="btn bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white rounded-full me-2 mt-2">살펴보기</Link>
                                            <Link to="/upload-work" className="btn bg-transparent hover:bg-yellow-500 border-yellow-500 text-yellow-500 hover:text-white rounded-full mt-2">판매하기</Link>
                                        </div>
                                    </div>
                                    <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-yellow-500/10 dark:after:bg-yellow-500/30 after:-bottom-[50px] after:start-[30%] after:-z-1 after:rounded-full after:animate-ping"></div>
                                </div>

                                <div className="md:col-span-5 relative">
                                    <div className="tiny-one-icon-item">
                                        <TinySlider settings={settings}>
                                            {discoverItems.map((el, index) => (
                                                <div className="tiny-slide" key={index}>
                                                    <div className="m-2 p-3 bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                                                        <Link to="/item-detail" className="group relative overflow-hidden rounded-lg">
                                                            <img src={el.image} className="rounded-lg" alt="" />

                                                            <span className="absolute bottom-5 start-5">
                                                                <span className="mb-2 block">
                                                                    <span className="font-semibold text-white">{el.title}</span>
                                                                </span>

                                                                <span className="flex items-center">
                                                                    <img src={el.avatar} className="rounded-full h-8 w-8" alt="" />
                                                                    <span className="ms-2 text-[15px] font-medium text-white">{el.subtext}</span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </TinySlider>
                                    </div>

                                    <div className="overflow-hidden after:content-[''] after:absolute after:h-14 after:w-14 after:bg-yellow-500/10 dark:after:bg-yellow-500/30 after:-top-[50px] after:start-[30%] after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <CreatorThree title="베스트 뮤지션 & 판매자 of" description="금주의 베스트셀러 NFT"
                    data={data} />
                <Feature />
                <ExploreTtem title="핫 아이템 구경하기!" data={discoverItems} />
                <Blog />
                <GetTouch />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
