import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { Link } from 'react-router-dom';
import DiscoverItems from '../../components/discover-items';


const single =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/avatar/5.jpg';
const image =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/avatar/1.jpg';
const image1 =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/avatar/2.jpg';
const image2  =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/3.gif';
const image5  =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/avatar/5.jpg';
const image6  =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/avatar/6.jpg';
const item1  =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/19.jpg';
const item2  =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/2.gif';
const item3  =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/18.jpg';
const item5  =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/5.jpg';
const item6  =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/17.jpg';
const item7  =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/16.jpg';
const item8  =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/15.jpg';
const item9  =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/13.jpg';
const item10  =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/12.jpg';
const item11  =  'https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/11.jpg';

export default function CreatorProfile() {
    const loadFile = (event) => {
        const image = document.getElementById(event.target.name);
        image.src = URL.createObjectURL(event.target.files[0]);
    };

    const collectionData = [
        {
            image: item11,
            title: 'Test 111',
            subtext: '@고백점프',
            items: 25,
            avatar: image,
            price: '3.5 ETH',
            highestBid: '3.55 ETH'
        },        
        {
            image: item1,
            title: 'Test 222',
            subtext: '@고백점프',
            items: 25,
            avatar: image,
            price: '3.5 ETH',
            highestBid: '3.55 ETH'
        },
        {
            image: item2,
            title: 'Test 333',
            subtext: '@고백점프',
            items: 25,
            avatar: image,
            price: '3.5 ETH',
            highestBid: '3.55 ETH'
        },
        {
            image: item3,
            title: 'Test 444',
            subtext: '@고백점프',
            items: 25,
            avatar: image,
            price: '3.5 ETH',
            highestBid: '3.55 ETH'
        },
        {
            image: item5,
            title: 'Test 555',
            subtext: '@고백점프',
            items: 25,
            avatar: image,
            price: '3.5 ETH',
            highestBid: '3.55 ETH'
        },
        {
            image: image6,
            title: 'Test 666',
            subtext: '@고백점프',
            items: 25,
            avatar: image,
            price: '3.5 ETH',
            highestBid: '3.55 ETH'
        },
        {
            image: item6,
            title: '브이로그',
            subtext: '@고백점프',
            items: 25,
            avatar: image,
            price: '3.5 ETH',
            highestBid: '3.55 ETH'
        },
        {
            image: item8,
            title: 'Test 111',
            subtext: '@고백점프',
            items: 25,
            avatar: image,
            price: '3.5 ETH',
            highestBid: '3.55 ETH'
        },
        {
            image: item3,
            title: 'Test 111',
            subtext: '@고백점프',
            items: 25,
            avatar: image,
            price: '3.5 ETH',
            highestBid: '3.55 ETH'
        },

        {
            image: item9,
            title: 'Test 111',
            subtext: '@고백점프',
            items: 25,
            avatar: image,
            price: '3.5 ETH',
            highestBid: '3.55 ETH'
        },
        {
            image: item7,
            title: 'Test 111',
            subtext: '@고백점프',
            items: 25,
            avatar: image,
            price: '3.5 ETH',
            highestBid: '3.55 ETH'
        },
        {
            image: item10,
            title: 'Test 111',
            subtext: '@고백점프',
            items: 25,
            avatar: image,
            price: '3.5 ETH',
            highestBid: '3.55 ETH'
        },
    ];


    const [localCollectionData, setLocalCollectionData] = useState([]);

    useEffect(() => {
        const storedData = localStorage.getItem('nftCollection'); // 키 값을 'nftCollection'으로 변경
        if (storedData) {
            setLocalCollectionData(JSON.parse(storedData));
        }
    }, []);

    const transformedLocalData = localCollectionData.map(data => ({
        image: data.image,
        title: data.title,
        subtext: data.Owner,
        items: data.price,
        price: data.price + ' ETH',
        avatar: image, // avatar는 어떤 데이터를 사용할지 명확하지 않아 일단 image를 넣었어요.
        highestBid: '-' // Highest Bid는 '-'로 표시
    }));
    
    const combinedCollectionData = [...collectionData, ...transformedLocalData];


    return (
        <>
            <Navbar />
            <section className="relative md:pb-24 pb-16 lg:mt-24 mt-[74px]">
                <div className="lg:container container-fluid">
                    <div className="group profile-banner relative overflow-hidden text-transparent lg:rounded-xl shadow dark:shadow-gray-700">
                        <input id="pro-banner" name="profile-banner" type="file" className="hidden" onChange={loadFile} />
                        <div className="relative shrink-0">
                            <img src={single} className="h-80 w-full object-cover" id="profile-banner" alt="" />
                            <div className="absolute inset-0 bg-slate/10 group-hover:bg-slate-900/40 transition duration-500" /></div>
                        <label className="absolute inset-0 cursor-pointer" htmlFor="pro-banner"></label>
                    </div>
                </div>

                <div className="md:flex justify-center">
                    <div className="md:w-full">
                        <div className="relative -mt-[60px] text-center">
                            <div className="group profile-pic w-[112px] mx-auto">
                                <input id="pro-img" name="profile-image" type="file" className="hidden" onChange={loadFile} />
                                <div>
                                    <div className="relative h-28 w-28 mx-auto rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800 overflow-hidden">
                                        <img src={image} className="rounded-full" id="profile-image" alt="" />
                                        <div className="absolute inset-0 group-hover:bg-slate-900/40 transition duration-500"></div>
                                        <label className="absolute inset-0 cursor-pointer" htmlFor="pro-img"></label>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-6">
                                <h5 className="text-xl font-semibold">고백점프 <i className="mdi mdi-check-decagram text-emerald-600 align-middle text-lg"></i></h5>
                                <p className="text-slate-400 text-[16px] mt-1">Created by <a href="/" className="text-yellow-500 font-semibold">1x5484dcdvcdscds56c4</a></p>

                                <div className="mt-4">
                                    <Link to="#" className="btn btn-sm rounded-full bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white mx-1"><i className="mdi mdi-plus"></i> Follow me</Link>
                                    <Link to="#" className="btn btn-icon btn-sm rounded-full bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white mx-1"><i className="mdi mdi-account-plus"></i></Link>
                                    <Link to="/creator-profile-edit" className="btn btn-icon btn-sm rounded-full bg-yellow-500/5 hover:bg-yellow-500 border-yellow-500/10 hover:border-yellow-500 text-yellow-500 hover:text-white mx-1"><i className="mdi mdi-cog"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DiscoverItems data={combinedCollectionData} />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
