import React, { useState } from 'react'
import image from '../../assets/images/items/1.jpg';
import image1 from '../../assets/images/avatar/1.jpg';
import image2 from '../../assets/images/items/2.gif';
import image3 from '../../assets/images/avatar/2.jpg';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import image4 from '../../assets/images/items/3.jpg';
import image5 from '../../assets/images/items/3.gif';
import { Link } from 'react-router-dom';

export default function ExploreThree() {

    const [selectedCategory, setSelectedCategory] = useState(null);

    let data = [
        {
            image: image,
            subtext: '@StreetBoy',
            title: 'Genuine Undead #3902',
            avatar: image1,
            category: 'Games'
        },
        {
            image: image1,
            subtext: '@CutieGirl',
            title: 'Windchime #768',
            avatar: image2,
            category: 'Games'

        },
        {
            image: image2,
            subtext: '@버터시경',
            title: 'Probably A Label #3277',
            avatar: image3,
            category: 'Art'
        }, {
            image: image3,
            subtext: '@NorseQueen',
            title: 'Probably A Label #1711',
            avatar: image4,
            category: 'Music'
        }, {
            image: image4,
            subtext: '@BigBull',
            title: 'Shibuya Scramble Punks',
            avatar: image5,
            category: 'Video'
        },
        {
            image: image,
            subtext: '@StreetBoy',
            title: 'Genuine Undead #3902',
            avatar: image1,
            category: 'Games'
        },
        {
            image: image1,
            subtext: '@CutieGirl',
            title: 'Windchime #768',
            avatar: image2,
            category: 'Games'
        },
        {
            image: image2,
            subtext: '@버터시경',
            title: 'Probably A Label #3277',
            avatar: image3,
            category: 'Art'
        },
        {
            image: image,
            subtext: '@StreetBoy',
            title: 'Genuine Undead #3902',
            avatar: image1,
            category: 'Music'
        },
        {
            image: image1,
            subtext: '@CutieGirl',
            title: 'Windchime #768',
            avatar: image2,
            category: 'Memes'
        },
        {
            image: image2,
            subtext: '@버터시경',
            title: 'Probably A Label #3277',
            avatar: image3,
            category: 'Memes'
        }, {
            image: image4,
            subtext: '@BigBull',
            title: 'Shibuya Scramble Punks',
            avatar: image5,
            category: 'Music'
        },
    ]
    const matchCategory = (category) => {
        setSelectedCategory(category);
    };

    const filteredData = selectedCategory
        ? data.filter((item) => item.category === selectedCategory)
        : data;




    return (
        <>
            <Navbar />

            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Explore Items</h3>

                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">K-Sori</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Explore</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid grid-cols-1 items-center gap-[30px]">
                        <div className="filters-group-wrap text-center">
                            <div className="filters-group">
                                <ul className="mb-0 list-none container-filter-box filter-options">
                                    <li onClick={() => matchCategory(null)} className={`${selectedCategory === null ? 'active' : ''} inline-block font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500 `} data-group="all"><i className="uil uil-browser"></i>  All</li>
                                    <li onClick={() => matchCategory('Games')} className={`${selectedCategory === 'Games' ? 'active' : ''} inline-block font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500`}><i className="uil uil-volleyball"></i> Games</li>
                                    <li onClick={() => matchCategory('Art')} className={`${selectedCategory === 'Art' ? 'active' : ''} inline-block font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500`} data-group="art"><i className="uil uil-chart-pie-alt"></i> Art</li>
                                    <li onClick={() => matchCategory('Music')} className={`${selectedCategory === 'Music' ? 'active' : ''} inline-block font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500`} data-group="music"><i className="uil uil-music"></i> Music</li>
                                    <li onClick={() => matchCategory('Video')} className={`${selectedCategory === 'Video' ? 'active' : ''} inline-block font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500`} data-group="video"><i className="uil uil-camera-plus"></i> Video</li>
                                    <li onClick={() => matchCategory('Memes')} className={`${selectedCategory === 'Memes' ? 'active' : ''} inline-block font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500`} data-group="memes"><i className="uil uil-rocket"></i> Memes</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
                        {
                            filteredData.map((item, index) => (
                                <div key={index} className="picture-item shuffle-item shuffle-item--visible" data-groups='["games"]'>
                                    <div className="group relative overflow-hidden p-2 rounded-lg bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700 transition-all duration-500 hover:-mt-2 h-fit">
                                        <div className="relative overflow-hidden">
                                            <div className="relative overflow-hidden rounded-lg">
                                                <img src={item.image} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />
                                            </div>

                                            <div className="absolute -bottom-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-center transition-all duration-500">
                                                <Link to="/item-detail" className="btn btn-sm rounded-full bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white"><i className="mdi mdi-lightning-bolt"></i> Buy Now</Link>
                                            </div>

                                            <div className="absolute top-2 end-2 opacity-0 group-hover:opacity-100 transition-all duration-500">
                                                <a href="/" className="btn btn-icon btn-sm rounded-full bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white"><i className="mdi mdi-plus"></i></a>
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <div className="flex items-center">
                                                <img src={item.avatar} className="rounded-full h-8 w-8" alt="" />
                                                <Link to="/creator-profile" className="ms-2 text-[15px] font-medium text-slate-400 hover:text-yellow-500">{item.subtext}</Link>
                                            </div>

                                            <div className="my-3">
                                                <Link to="/item-detail" className="font-semibold hover:text-yellow-500">{item.title}</Link>
                                            </div>

                                            <div className="flex justify-between p-2 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700">
                                                <div>
                                                    <span className="text-[16px] font-medium text-slate-400 block">Price</span>
                                                    <span className="text-[16px] font-semibold block"><i className="mdi mdi-ethereum"></i> 3.5 ETH</span>
                                                </div>

                                                <div>
                                                    <span className="text-[16px] font-medium text-slate-400 block">Highest Bid</span>
                                                    <span className="text-[16px] font-semibold block"><i className="mdi mdi-ethereum"></i> 3.55 ETH</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
