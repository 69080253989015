import React, { useState, useEffect } from 'react';
import './admin-nft-info.css';


export default function AdminNFTInfo({ data }) {
    const [loading, setLoading] = useState(Array(data.length).fill(true));

    useEffect(() => {
        // 명시적으로 loading 상태를 리셋
        setLoading(Array(data.length).fill(true));
    
        data.forEach((_, index) => {
            const randomTime = Math.floor(Math.random() * (15000 - 2000) + 5000);
            setTimeout(() => {
                setLoading(prevLoading => {
                    const newLoading = [...prevLoading];
                    newLoading[index] = false;
                    return newLoading;
                });
            }, randomTime);
        });
    }, [data]);
    

    return (
        <div className="container mt-10">
            <h3 className="text-center text-[30px] font-semibold">NFT Detailed Information</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-6">
                {data.map((item, index) => (
                    <div key={index} className="p-4 rounded-lg bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 shadow dark:shadow-gray-700">
                        {loading[index] ? (
                            <div className="flex justify-center items-center">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <>
                                <h4 className="font-semibold">{item.title}</h4>
                                <p>Owner: {item.subtext}</p>
                                <p>Price: {item.price}</p>
                                <p>Highest Bid: {item.highestBid}</p>
                                <p>NFT ID: {item.nftID}</p>
                                <p>Owner Address: {item.ownerAddress}</p>
                                <p>Minted Date: {item.mintedDate}</p>
                                <p>Transaction Hash: {item.transactionHash}</p>
                                <p>Contract Address: {item.contractAddress}</p>
                                <p>Number of Transfers: {item.numberOfTransfers}</p>
                                <p>Metadata: {item.metadata}</p>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
