import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { Link } from "react-router-dom";
import AdminNFTInfo from "../../components/admin-nft-info"; // 새로운 컴포넌트를 import 해주세요.

const single =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/avatar/5.jpg";
const image =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/avatar/1.jpg";
const image1 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/avatar/2.jpg";
const image2 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/3.gif";
const image5 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/avatar/5.jpg";
const image6 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/avatar/6.jpg";
const item1 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/19.jpg";
const item2 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/2.gif";
const item3 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/18.jpg";
const item5 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/5.jpg";
const item6 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/17.jpg";
const item7 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/16.jpg";
const item8 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/15.jpg";
const item9 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/13.jpg";
const item10 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/12.jpg";
const item11 =
  "https://raw.githubusercontent.com/lavickim/cosh_iamge_hosting/main/items/11.jpg";

export default function AdminPage() {
  const loadFile = (event) => {
    const image = document.getElementById(event.target.name);
    image.src = URL.createObjectURL(event.target.files[0]);
  };

  const collectionData = [
    {
      image: item11,
      title: "Test 111",
      subtext: "@고백점프",
      items: 25,
      avatar: image,
      price: "3.5 ETH",
      highestBid: "3.55 ETH",
      nftID: "0x1234a1cd",
      ownerAddress: "0x6cb88d70",
      mintedDate: "2023-09-19",
      transactionHash: "0x9abc12d4",
      contractAddress: "0xdefg99h8",
      numberOfTransfers: 12,
      metadata: "Example Metadata 1",
    },
    {
      image: item1,
      title: "Test 222",
      subtext: "@고백점프",
      items: 25,
      avatar: image,
      price: "3.5 ETH",
      highestBid: "3.55 ETH",
      nftID: "0x1234b2cd",
      ownerAddress: "0x6cb88d70",
      mintedDate: "2023-09-18",
      transactionHash: "0x9abc23d4",
      contractAddress: "0xdefg99h8",
      numberOfTransfers: 15,
      metadata: "Example Metadata 2",
    },
    {
      image: item2,
      title: "Test 111",
      subtext: "@고백점프",
      items: 25,
      avatar: image,
      price: "3.5 ETH",
      highestBid: "3.55 ETH",
      nftID: "0x1234z9cd",
      ownerAddress: "0x6cb88d70",
      mintedDate: "2023-09-17",
      transactionHash: "0x9abc99d4",
      contractAddress: "0xdefg99h8",
      numberOfTransfers: 20,
      metadata: "Example Metadata 12",
    },
    {
      image: item3,
      title: "Test 111",
      subtext: "@고백점프",
      items: 25,
      avatar: image,
      price: "3.5 ETH",
      highestBid: "3.55 ETH",
      nftID: "0x1234y8cd",
      ownerAddress: "0x6cb88d70",
      mintedDate: "2023-09-16",
      transactionHash: "0x9abc88d4",
      contractAddress: "0xdefg99h8",
      numberOfTransfers: 18,
      metadata: "Example Metadata 11",
    },
    {
      image: item5,
      title: "Test 111",
      subtext: "@고백점프",
      items: 25,
      avatar: image,
      price: "3.5 ETH",
      highestBid: "3.55 ETH",
      nftID: "0x1234x7cd",
      ownerAddress: "0x6cb88d70",
      mintedDate: "2023-09-15",
      transactionHash: "0x9abc77d4",
      contractAddress: "0xdefg99h8",
      numberOfTransfers: 16,
      metadata: "Example Metadata 10",
    },
    {
      image: image6,
      title: "Test 444",
      subtext: "@고백점프",
      items: 25,
      avatar: image,
      price: "3.5 ETH",
      highestBid: "3.55 ETH",
      nftID: "0x1234w6cd",
      ownerAddress: "0x6cb88d70",
      mintedDate: "2023-09-14",
      transactionHash: "0x9abc66d4",
      contractAddress: "0xdefg99h8",
      numberOfTransfers: 14,
      metadata: "Example Metadata 9",
    },
    {
      image: item6,
      title: "Test 555",
      subtext: "@고백점프",
      items: 25,
      avatar: image,
      price: "3.5 ETH",
      highestBid: "3.55 ETH",
      nftID: "0x1234v5cd",
      ownerAddress: "0x6cb88d70",
      mintedDate: "2023-09-13",
      transactionHash: "0x9abc55d4",
      contractAddress: "0xdefg99h8",
      numberOfTransfers: 12,
      metadata: "Example Metadata 8",
    },
    {
      image: item8,
      title: "Test 666",
      subtext: "@고백점프",
      items: 25,
      avatar: image,
      price: "3.5 ETH",
      highestBid: "3.55 ETH",
      nftID: "0x1234u4cd",
      ownerAddress: "0x6cb88d70",
      mintedDate: "2023-09-12",
      transactionHash: "0x9abc44d4",
      contractAddress: "0xdefg99h8",
      numberOfTransfers: 10,
      metadata: "Example Metadata 7",
    },
    {
      image: item3,
      title: "브이로그",
      subtext: "@고백점프",
      items: 25,
      avatar: image,
      price: "3.5 ETH",
      highestBid: "3.55 ETH",
      nftID: "0x1234t3cd",
      ownerAddress: "0x6cb88d70",
      mintedDate: "2023-09-11",
      transactionHash: "0x9abc33d4",
      contractAddress: "0xdefg99h8",
      numberOfTransfers: 8,
      metadata: "Example Metadata 6",
    },

    {
      image: item9,
      title: "Test 111",
      subtext: "@고백점프",
      items: 25,
      avatar: image,
      price: "3.5 ETH",
      highestBid: "3.55 ETH",
      nftID: "0x1234s2cd",
      ownerAddress: "0x6cb88d70",
      mintedDate: "2023-09-10",
      transactionHash: "0x9abc22d4",
      contractAddress: "0xdefg99h8",
      numberOfTransfers: 6,
      metadata: "Example Metadata 5",
    },
    {
      image: item7,
      title: "Test 111",
      subtext: "@고백점프",
      items: 25,
      avatar: image,
      price: "3.5 ETH",
      highestBid: "3.55 ETH",
      nftID: "0x1234r1cd",
      ownerAddress: "0x6cb88d70",
      mintedDate: "2023-09-09",
      transactionHash: "0x9abc11d4",
      contractAddress: "0xdefg99h8",
      numberOfTransfers: 4,
      metadata: "Example Metadata 4",
    },
    {
      image: item10,
      title: "Test 111",
      subtext: "@고백점프",
      items: 25,
      avatar: image,
      price: "3.5 ETH",
      highestBid: "3.55 ETH",
      nftID: "0x1234z9cd",
      ownerAddress: "0x6cb88d70",
      mintedDate: "2023-09-17",
      transactionHash: "0x9abc99d4",
      contractAddress: "0xdefg99h8",
      numberOfTransfers: 20,
      metadata: "Example Metadata 12",
    },
  ];

  const [localCollectionData, setLocalCollectionData] = useState([]);

  useEffect(() => {
    const storedData = localStorage.getItem("nftCollection"); // 키 값을 'nftCollection'으로 변경
    if (storedData) {
      setLocalCollectionData(JSON.parse(storedData));
    }
  }, []);

  const transformedLocalData = localCollectionData.map((data) => ({
    image: data.image,
    title: data.title,
    subtext: data.Owner,
    items: data.price,
    price: data.price + " ETH",
    avatar: image,
    highestBid: "-",
    nftID: "0x" + data.nftID,
    ownerAddress: data.ownerAddress,
    mintedDate: data.mintedDate,
    transactionHash: data.transactionHash,
    contractAddress: data.contractAddress,
    numberOfTransfers: data.numberOfTransfers,
    metadata: data.metadata,
  }));

  const combinedCollectionData = [...collectionData, ...transformedLocalData];

  return (
    <>
      <Navbar />
      <section className="relative md:pb-24 pb-16 lg:mt-24 mt-[74px]">
        <AdminNFTInfo data={combinedCollectionData} />
      </section>
      <Footer />
      <Switcher />
    </>
  );
}
