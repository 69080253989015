import React from 'react'
import { Link } from 'react-router-dom';

export default function GetTouch() {
    return (
        <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 text-center">
                <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">궁금한 점이 있으신가요? 언제나 열려 있습니다!</h3>

                <p className="text-slate-400 max-w-xl mx-auto">우리는 메타버스 K-Sori의 훌륭한 아티스트와 그들의 팬, 그리고 독특한 토큰 수집가들을 연결하는 NFT 마켓플레이스입니다!</p>

                <div className="mt-6">
                    <Link to="/helpcenter-support" className="btn bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white rounded-full me-2 mt-2"><i className="uil uil-phone"></i> Contact us</Link>
                </div>
            </div>
        </div>
    )
}
