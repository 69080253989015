import image from '../../assets/images/items/1.jpg';
import image1 from '../../assets/images/avatar/1.jpg';
import image2 from '../../assets/images/items/2.gif';
import image3 from '../../assets/images/avatar/2.jpg';
import image4 from '../../assets/images/items/3.jpg';
import image5 from '../../assets/images/items/3.gif';
import image6 from '../../assets/images/items/4.jpg';
import image7 from '../../assets/images/avatar/3.jpg';
import image8 from '../../assets/images/items/5.jpg';
import React, { useEffect } from 'react'
import Navbar from '../../components/navbar'
import TinySlider from "tiny-slider-react";
import CreatorThree from '../../components/creator-three';
import Feature from '../../components/feature';
import Blog from '../../components/blog';
import GetTouch from '../../components/get-touch';
import Footer from '../../components/footer';
import ExploreTtem from '../../components/explore-item';
import { Link } from 'react-router-dom';
import Switcher from '../../components/switcher';

const settings = {
    items: 1,
    controls: true,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    nav: false,
    speed: 400,
    gutter: 0,
    controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>']
};
export default function IndexFive() {
    useEffect(() => {
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
    }, []);

    const data = [
        {
            avatar: image,
            name: "Steven Townsend",
            subtext: "@StreetBoy"
        },
        {
            avatar: image1,
            name: "Tiffany Betancourt",
            subtext: "@CutieGirl"
        },
        {
            avatar: image2,
            name: "Jacqueline Burns",
            subtext: "@버터시경"
        },
        {
            avatar: image3,
            name: "Mari Harrington",
            subtext: "@NorseQueen"
        },
        {
            avatar: image4,
            name: "Floyd Glasgow",
            subtext: "@BigBull"
        },
        {
            avatar: image5,
            name: "Donna Schultz",
            subtext: "@Angel"
        },
        {
            avatar: image6,
            name: "Joshua Morris",
            subtext: "@CrazyAnyone"
        },
        {
            avatar: image7,
            name: "Rosaria Vargas",
            subtext: "@Princess"
        },
        {
            avatar: image8,
            name: "Carl Williams",
            subtext: "@LooserBad"
        }
    ];

    const discoverItems = [
        {
            image: image,
            subtext: '@StreetBoy',
            title: 'Genuine Undead #3902',
            avatar: image1
        },
        {
            image: image1,
            subtext: '@CutieGirl',
            title: 'Windchime #768',
            avatar: image2
        },
        {
            image: image2,
            subtext: '@버터시경',
            title: 'Probably A Label #3277',
            avatar: image3
        }, {
            image: image3,
            subtext: '@NorseQueen',
            title: 'Probably A Label #1711',
            avatar: image4
        }, {
            image: image4,
            subtext: '@BigBull',
            title: 'Shibuya Scramble Punks',
            avatar: image5
        },
        {
            image: image,
            subtext: '@StreetBoy',
            title: 'Genuine Undead #3902',
            avatar: image1
        },
        {
            image: image1,
            subtext: '@CutieGirl',
            title: 'Windchime #768',
            avatar: image2
        },
        {
            image: image2,
            subtext: '@버터시경',
            title: 'Probably A Label #3277',
            avatar: image3
        }
    ]
    return (
        <>
            <Navbar />
            <section className="relative lg:pt-24 pt-[74px] overflow-hidden">
                <div className="container-fluid lg:px-10 md:px-3 relative overflow-hidden">
                    <span className="absolute blur-[200px] w-[600px] h-[600px] rounded-full top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/40 to-yellow-500/40 dark:from-red-600/60 dark:to-yellow-500/60"></span>
                    <div className="lg:py-24 py-[74px] md:rounded-lg shadow dark:shadow-gray-800 bg-yellow-600/10 dark:bg-yellow-500/20">
                        <div className="container">
                            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                                <div className="md:col-span-7">
                                    <div className="md:me-6">
                                        <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span className="bg-gradient-to-l from-red-600 to-yellow-500 text-transparent bg-clip-text">Buy, sell,</span>    and <br /> showcase NFTs</h4>
                                        <p className="text-lg max-w-xl">We are a huge marketplace dedicated to connecting great artists of all K-Sori with their fans and unique token collectors!</p>

                                        <div className="mt-6">
                                            <Link to="/explore-two" className="btn bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white rounded-full me-2 mt-2">Explore Now</Link>
                                            <Link to="/upload-work" className="btn bg-transparent hover:bg-yellow-500 border-yellow-500 text-yellow-500 hover:text-white rounded-full mt-2">Sell Now</Link>
                                        </div>
                                    </div>
                                    <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-yellow-500/10 dark:after:bg-yellow-500/30 after:-bottom-[50px] after:start-[30%] after:-z-1 after:rounded-full after:animate-ping"></div>
                                </div>

                                <div className="md:col-span-5 relative">
                                    <div className="tiny-one-icon-item">
                                        <TinySlider settings={settings}>
                                            {discoverItems.map((el, index) => (
                                                <div className="tiny-slide" key={index}>
                                                    <div className="m-2 p-3 bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                                                        <Link to="/item-detail" className="group relative overflow-hidden rounded-lg">
                                                            <img src={el.image} className="rounded-lg" alt="" />

                                                            <span className="absolute bottom-5 start-5">
                                                                <span className="mb-2 block">
                                                                    <span className="font-semibold text-white">{el.title}</span>
                                                                </span>

                                                                <span className="flex items-center">
                                                                    <img src={el.avatar} className="rounded-full h-8 w-8" alt="" />
                                                                    <span className="ms-2 text-[15px] font-medium text-white">{el.subtext}</span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </TinySlider>
                                    </div>

                                    <div className="overflow-hidden after:content-[''] after:absolute after:h-14 after:w-14 after:bg-yellow-500/10 dark:after:bg-yellow-500/30 after:-top-[50px] after:start-[30%] after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <CreatorThree title="베스트 뮤지션 & 판매자 of" description="금주의 베스트셀러 NFT"
                    data={data} />

                <Feature />
                <ExploreTtem title="Explore Hot Items" data={discoverItems} />
                <Blog />
                <GetTouch />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
