 // eslint-disable-next-line
import React from 'react'
import Navbar from '../../components/navbar'

import CreatorTwo from '../../components/creator-two';
import image from '../../assets/images/avatar/1.jpg';
import image1 from '../../assets/images/avatar/2.jpg';
import image2 from '../../assets/images/items/3.gif';
import image3 from '../../assets/images/avatar/3.jpg';
import image4 from '../../assets/images/avatar/4.jpg';
import image5 from '../../assets/images/avatar/5.jpg';
import image8 from '../../assets/images/avatar/7.jpg';
import gif1 from '../../assets/images/items/1.gif';
import gif2 from '../../assets/images/items/1.gif';


import item2 from '../../assets/images/items/2.gif';
import item5 from '../../assets/images/items/5.jpg';
import item6 from '../../assets/images/items/6.jpg';
import item7 from '../../assets/images/items/7.jpg';
import item9 from '../../assets/images/items/9.jpg';
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { Link } from 'react-router-dom';

export default function Creators() {
    const data = [
        {
            avatar: image,
            name: "Ive",
            subtext: "@Ive",
            subImages: [gif1, image1, image3]
        },
        {
            avatar: image1,
            name: "Newjeans",
            subtext: "@NJ",
            subImages: [image, item2, image4]
        },
        {
            avatar: image2,
            name: "Tiffany",
            subtext: "@Tif",
            subImages: [image, item2, image2]
        },
        {
            avatar: image3,
            name: "Blackpink",
            subtext: "@blackpink",
            subImages: [item5, item6, image2]
        },
        {
            avatar: image4,
            name: "gidle",
            subtext: "@idle",
            subImages: [item7, image8, item9]
        },
        {
            avatar: image5,
            name: "BOL4",
            subtext: "@bol4",
            subImages: [item7, image8, item9]
        },
        {
            avatar: image,
            name: "codeshift",
            subtext: "@codeshift",
            subImages: [gif2, image1, image3]
        },
        {
            avatar: image1,
            name: "goback",
            subtext: "@goback",
            subImages: [image, item2, image4]
        },
        {
            avatar: image2,
            name: "Nenene",
            subtext: "@nene",
            subImages: [image, item2, image2]
        },
        {
            avatar: image3,
            name: "Nana",
            subtext: "@nana",
            subImages: [item5, item6, image2]
        },
        {
            avatar: image4,
            name: "nono",
            subtext: "@Nono",
            subImages: [item7, image8, item9]
        },
        {
            avatar: image5,
            name: "Suppp",
            subtext: "@sss",
            subImages: [item7, image8, item9]
        },
    ];
    return (
        <>
            <Navbar />

            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Creators</h3>

                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">K-Sori</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Creators</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className='relative md:py-24 py-16'>

                <CreatorTwo data={data} />

            </section>
            <Footer />
            <Switcher />
        </>
    )
}
