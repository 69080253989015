import React, { useEffect } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Select from 'react-select'
import image from '../../assets/images/avatar/1.jpg';
import image1 from '../../assets/images/avatar/2.jpg';
import image2 from '../../assets/images/items/3.gif';
import image5 from '../../assets/images/avatar/5.jpg';
import image6 from '../../assets/images/avatar/6.jpg';

import item1 from '../../assets/images/items/1.jpg';
import item2 from '../../assets/images/items/4.gif';
import item22 from '../../assets/images/items/1.gif';
import item222 from '../../assets/images/items/5.gif';
import item3 from '../../assets/images/items/3.jpg';
import item5 from '../../assets/images/items/5.jpg';
import item6 from '../../assets/images/items/6.jpg';
import DiscoverItems from '../../components/discover-items';
import Switcher from '../../components/switcher';
const options = [
    { value: '뮤지션', label: '뮤지션' },
    { value: '공연영상', label: '공연영상' },
    { value: '애장품', label: '뮤지션 애장품' },
    { value: '앨범', label: '앨범' },
    { value: '비하인드 Scene', label: '비하인드 Scene' },
];

const buyOptions = [
    { value: 'now', label: '구매' },
    { value: 'auctions', label: '경매' },
    { value: 'offers', label: '제안' }
]
const rateOptions = [
    { value: 'top', label: '인기순위(위)' },
    { value: 'low', label: '인기순위(아래)' }
]
export default function ExploreOne() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    const collectionData = [
        {
            image: item1,
            title: '디지털 컬렉션',
            subtext: '@goback',
            items: 25,
            avatar: image
        },
        {
            image: item2,
            title: 'GIF 컬렉션',
            subtext: '@codeshift',
            items: 25,
            avatar: image1
        },
        {
            image: item3,
            title: '영상 컬렉션',
            subtext: '@June',
            items: 25,
            avatar: image2
        },
        {
            image: item5,
            title: '앨범 컬렉션',
            subtext: '@Jica',
            items: 25,
            avatar: image5
        },
        {
            image: image6,
            title: '애장품 컬렉션',
            subtext: '@Jiyoon',
            items: 25,
            avatar: item6
        },
        {
            image: item1,
            title: '디지털 컬렉션',
            subtext: '@YH___',
            items: 25,
            avatar: image
        },
        {
            image: item22,
            title: 'GIF 컬렉션',
            subtext: '@BlackPink',
            items: 25,
            avatar: image1
        },
        {
            image: item3,
            title: '공연 컬렉션',
            subtext: '@NewJeans',
            items: 25,
            avatar: image2
        },
        {
            image: item1,
            title: '공연 컬렉션',
            subtext: '@IVE',
            items: 25,
            avatar: image
        },
        {
            image: item222,
            title: 'GIF 컬렉션',
            subtext: '@(G)-IDLE)',
            items: 25,
            avatar: image1
        },
        {
            image: item3,
            title: '공연 컬렉션',
            subtext: '@Sorisori',
            items: 25,
            avatar: image2
        },
        {
            image: item5,
            title: '애장품 컬렉션',
            subtext: '@FiftyFifty',
            items: 25,
            avatar: image5
        },
    ];
    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Explore Items</h3>

                    </div>
                </div>

            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:pb-24 pb-16 -mt-16">
                <div className="container z-1">
                    <div className="grid grid-cols-1">
                        <form className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow dark:shadow-gray-700">
                            <div className="registration-form text-dark text-start">
                                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                    <div>
                                        <label className="form-label font-medium dark:text-white">검색:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <i className="uil uil-search icons"></i>
                                            <input name="name" type="text" id="job-keyword" className="form-input w-full filter-input-box bg-gray-50 dark:bg-slate-800 border-0 focus:ring-transparent" placeholder="키워드 입력" />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-catagory" className="form-label font-medium dark:text-white">카테고리:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <i className="uil uil-apps icons"></i>
                                            <Select className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={options} />

                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-min-price" className="form-label font-medium dark:text-white">아이템:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <i className="uil uil-clock icons"></i>
                                            <Select className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={buyOptions} />

                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-max-price" className="form-label font-medium dark:text-white">정렬:</label>
                                        <div className="filter-search-form relative mt-2">
                                            <i className="uil uil-star icons"></i>
                                            <Select className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={rateOptions} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <DiscoverItems data={collectionData} />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
