import React from 'react'
import Navbar from '../../components/navbar'
import image from '../../assets/images/items/1.jpg';
import image1 from '../../assets/images/avatar/1.jpg';
import image2 from '../../assets/images/items/2.gif';
import image3 from '../../assets/images/avatar/2.jpg';
import image4 from '../../assets/images/items/3.jpg';
import image5 from '../../assets/images/items/3.gif';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';

export default function ExploreTwo() {
    const data = [
        {
            image: image,
            subtext: '@Pink',
            title: '블핑 #3902',
            avatar: image1
        },
        {
            image: image1,
            subtext: '@CutieGirl',
            title: '블핑 #768',
            avatar: image2
        },
        {
            image: image2,
            subtext: '@버터시경',
            title: '카리유니크 #3277',
            avatar: image3
        }, {
            image: image3,
            subtext: '@NorseQueen',
            title: '카리유니크23 #1711',
            avatar: image4
        }, {
            image: image4,
            subtext: '@BigBull',
            title: '도도클럽 #3902',
            avatar: image5
        },
        {
            image: image,
            subtext: '@StreetBoy',
            title: '도도 #3902',
            avatar: image1
        },
        {
            image: image1,
            subtext: '@CutieGirl',
            title: '코쉬 #768',
            avatar: image2
        },
        {
            image: image2,
            subtext: '@버터시경',
            title: '람 #3277',
            avatar: image3
        },
        {
            image: image,
            subtext: '@StreetBoy',
            title: '코쉬 #3902',
            avatar: image1
        },
        {
            image: image1,
            subtext: '@CutieGirl',
            title: '코코넛걸 #768',
            avatar: image2
        },
        {
            image: image2,
            subtext: '@버터시경',
            title: '그램고연 #3277',
            avatar: image3
        }, {
            image: image4,
            subtext: '@BigBull',
            title: '시부야 스타일',
            avatar: image5
        },
    ]

    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Explore Items</h3>

                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">K-Sori</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Explore</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-3">
                            <div className="shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900 sticky top-20">
                                <form>
                                    <div className="grid grid-cols-1 gap-3">
                                        <div>
                                            <label htmlFor="searchname" className="form-label font-semibold dark:text-white text-[15px]">검색</label>
                                            <div className="relative mt-2">
                                                <i className="uil uil-search text-lg absolute top-[6px] start-3"></i>
                                                <input name="search" id="searchname" type="text" className="form-input w-full py-2 px-3 ps-10 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-yellow-500 focus:ring-0" placeholder="검색" />
                                            </div>
                                        </div>

                                        <div className="mt-2">
                                            <label class="form-label font-semibold dark:text-white text-[15px]">카테고리:</label>
                                            <select class="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-yellow-500 focus:ring-0">
                                                <option>아트</option>
                                                <option>뮤직</option>
                                                <option>동영상</option>
                                                <option>비하인드 신</option>
                                                <option>콘서트 영상</option>
                                                <option>기타</option>                                                
                                            </select>
                                        </div>

                                        <div className="mt-2">
                                            <label class="form-label font-semibold dark:text-white text-[15px]">아이템:</label>
                                            <select class="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-yellow-500 focus:ring-0">
                                                <option>즉시 구매하기</option>
                                                <option>경매</option>
                                                <option>제안</option>
                                            </select>
                                        </div>

                                        <div className="mt-2">
                                            <label class="form-label font-semibold dark:text-white text-[15px]">정렬:</label>
                                            <select class="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-yellow-500 focus:ring-0">
                                                <option>상위 평점순</option>
                                                <option>하위 평점순</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="lg:col-span-9">
                            <div className="grid lg:grid-cols-3 grid-cols-1 gap-[30px]">
                                {
                                    data.map((item, index) => (
                                        <div key={index} className="group relative p-2 rounded-lg bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700 transition-all duration-500 h-fit">
                                            <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-yellow-500 rounded-lg -mt-1 group-hover:-mt-2 -ms-1 group-hover:-ms-2 h-[98%] w-[98%] -z-1 transition-all duration-500"></div>
                                            <div className="relative overflow-hidden">
                                                <div className="relative overflow-hidden rounded-lg">
                                                    <img src={item.image} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />
                                                </div>

                                                <div className="absolute -bottom-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-center transition-all duration-500">
                                                    <Link to="/item-detail" className="btn btn-sm rounded-full bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white"><i className="mdi mdi-lightning-bolt"></i> Buy Now</Link>
                                                </div>

                                                <div className="absolute top-2 end-2 opacity-0 group-hover:opacity-100 transition-all duration-500">
                                                    <Link to="#" className="btn btn-icon btn-sm rounded-full bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white"><i className="mdi mdi-plus"></i></Link>
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                <div className="flex items-center">
                                                    <img src={item.avatar} className="rounded-full h-8 w-8" alt="" />
                                                    <Link to="/creator-profile" className="ms-2 text-[15px] font-medium text-slate-400 hover:text-yellow-500">{item.subtext}</Link>
                                                </div>

                                                <div className="my-3">
                                                    <Link to="/item-detail" className="font-semibold hover:text-yellow-500">{item.title}</Link>
                                                </div>

                                                <div className="flex justify-between p-2 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700">
                                                    <div>
                                                        <span className="text-[16px] font-medium text-slate-400 block">Price</span>
                                                        <span className="text-[16px] font-semibold block"><i className="mdi mdi-ethereum"></i> 3.5 ETH</span>
                                                    </div>

                                                    <div>
                                                        <span className="text-[16px] font-medium text-slate-400 block">Highest Bid</span>
                                                        <span className="text-[16px] font-semibold block"><i className="mdi mdi-ethereum"></i> 3.55 ETH</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                            <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                                <div className="md:col-span-12 text-center">
                                    <nav>
                                        <ul className="inline-flex items-center -space-x-px">
                                            <li>
                                                <a href="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500">
                                                    <i className="uil uil-angle-left text-[20px]"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500">1</a>
                                            </li>
                                            <li>
                                                <a href="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500">2</a>
                                            </li>
                                            <li>
                                                <a href="/" aria-current="page" className="z-10 w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-white bg-yellow-500 shadow-sm dark:shadow-gray-700">3</a>
                                            </li>
                                            <li>
                                                <a href="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500">4</a>
                                            </li>
                                            <li>
                                                <a href="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-yellow-500 dark:hover:border-yellow-500 hover:bg-yellow-500 dark:hover:bg-yellow-500">
                                                    <i className="uil uil-angle-right text-[20px]"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
