 // eslint-disable-next-line
import React, { useEffect } from 'react'
import Navbar from '../../components/navbar'
import CollectionTwo from '../../components/collection-two'
import item1 from '../../assets/images/items/1.jpg';
import item2 from '../../assets/images/items/2.gif';
import item3 from '../../assets/images/items/3.jpg';
import item4 from '../../assets/images/items/8.jpg';
import item7 from '../../assets/images/items/5.jpg';
import item6 from '../../assets/images/items/6.jpg';
import image from '../../assets/images/blog/02.jpg';
import image1 from '../../assets/images/blog/03.jpg';
import image2 from '../../assets/images/blog/05.jpg';
import image6 from '../../assets/images/blog/09.jpg';
import image7 from '../../assets/images/blog/10.jpg';
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { Link } from 'react-router-dom';

export default function Collections() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    const collectionData = [
        {
            image: item1,
            title: '앨범 Collection',
            subtext: '@안고프니',
            items: 25,
            avatar: image
        },
        {
            image: item2,
            title: 'GIF Collection',
            subtext: '@sjsms',
            items: 25,
            avatar: image1
        },
        {
            image: item3,
            title: '안무 Collection',
            subtext: '@배고파',
            items: 25,
            avatar: image2
        },
        {
            image: item4,
            title: '화보 Collection',
            subtext: '@오늘따라',
            items: 25,
            avatar: image6
        },
        {
            image: image7,
            title: '공연 Collection',
            subtext: '@눈코입',
            items: 25,
            avatar: item7
        },
        {
            image: image,
            title: '사진 Collection',
            subtext: '@상처받은고양이',
            items: 25,
            avatar: item7
        },
        {
            image: image2,
            title: '비하인드 Collection',
            subtext: '@외토리',
            items: 25,
            avatar: item3
        },
        {
            image: item6,
            title: '뮤지션 작품 Collection',
            subtext: '@Looser',
            items: 25,
            avatar: item4
        }
    ];
    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Best Collections</h3>

                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">K-Sori</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Collections</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <CollectionTwo data={collectionData} />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
