import React from 'react'
import { Link } from 'react-router-dom';
import { Hexagon } from 'react-feather';

export default function Feature() {
    return (
        <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 text-center">
                <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">K-Sori is a web3 destination.</h3>

                <p className="text-slate-400 max-w-xl mx-auto">K-Sori 인디뮤지션 메타버스는 뮤지션과 음악애호가의 접점을 만듭니다.!</p>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-10 gap-[30px]">

                <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 fill-yellow-500/5 mx-auto rotate-[30deg]"></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-yellow-500 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                            <i className="uil uil-sitemap"></i>
                        </div>
                    </div>

                    <div className="mt-6">
                        <Link to="#" className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-yellow-500">Create Item</Link>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3">K-Sori 인디뮤지션 메타버스는 뮤지션과 음악애호가의 접점을 만듭니다.</p>
                    </div>
                </div>
                <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 fill-yellow-500/5 mx-auto rotate-[30deg]"></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-yellow-500 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                            <i className="uil uil-layers"></i>
                        </div>
                    </div>

                    <div className="mt-6">
                        <Link to="#" className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-yellow-500">Collect</Link>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3">K-Sori 인디뮤지션 메타버스는 뮤지션과 음악애호가의 접점을 만듭니다.</p>
                    </div>
                </div>
                <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 fill-yellow-500/5 mx-auto rotate-[30deg]"></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-yellow-500 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                            <i className="uil uil-camera-plus"></i>
                        </div>
                    </div>

                    <div className="mt-6">
                        <Link to="#" className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-yellow-500">Sell Item</Link>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3">K-Sori 인디뮤지션 메타버스는 뮤지션과 음악애호가의 접점을 만듭니다.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}
