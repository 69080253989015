import React, { useEffect, useState } from "react";
import image from "../../assets/images/avatar/1.jpg";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { Link } from "react-router-dom";

export default function UploadWork() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [gBase64Data, setGBase64Data] = useState(null);
  const [gTitle, setTitle] = useState(null);
  const [gDescription, setDescription] = useState(null);
  const [gMediaType, setMediaType] = useState(null);
  const [gPrice, setPrice] = useState(null);

  const generateRandomFileName = (originalFileName) => {
    const fileExtension = originalFileName.split(".").pop();
    const timeStamp = new Date().toISOString().replace(/[-:.]/g, "");
    const randomString = Math.random().toString(36).substring(2, 7);
    return `file_${timeStamp}_${randomString}.${fileExtension}`;
  };

  const saveNFT = async () => {
    const githubToken = "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"; // 환경 변수로 옮기는 것을 추천합니다.
    const repoName = "cosh_iamge_hosting";
    const branch = "main";
    const filePath = generateRandomFileName(selectedFile.name);
    const apiUrl = `https://api.github.com/repos/lavickim/${repoName}/contents/${filePath}`;

    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        Authorization: `token ${githubToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: "uploading a file",
        content: gBase64Data,
        branch: branch,
      }),
    });

    const data = await response.json();
    // alert(JSON.stringify(data, null, 2));

    if (!response.ok) {
      throw new Error("File upload failed");
    }

    if (data.content && data.content.download_url) {
      const nftData = {
        title: gTitle,
        description: gDescription,
        mediaType: gMediaType,
        price: gPrice,
        image: data.content.download_url,
        Timestamp: {
          Created_At: new Date(),
          Updated_At: new Date(),
        },
        nftID: "0x" + Math.random().toString(16).substr(2, 8),
        // ownerAddress: "0x" + Math.random().toString(16).substr(2, 8),
        ownerAddress: 0x6cb88d70,
        mintedDate: new Date().toLocaleDateString(),
        transactionHash: "0x" + Math.random().toString(16).substr(2, 8),
        // contractAddress: "0x" + Math.random().toString(16).substr(2, 8),
        contractAddress: "0xdefg99h8",
        // numberOfTransfers: Math.floor(Math.random() * 100),
        numberOfTransfers: 0,
        metadata: "Example Metadata",        
        Verified: false,
        Status: "Available",
        Owner: "고백점프",
        Creator: "고백점프",
        Transaction_History: [],
        Royalties: 0,
        Metadatas: {
          Resolution: "DefaultResolution",
          File_Size: "DefaultFileSize",
        },
        Unlockable_Content: "None",
        Editions: 1,
        Wonder: "None",
        Tags: [],
      };

      const existingNFTs =
        JSON.parse(localStorage.getItem("nftCollection")) || [];
      existingNFTs.push(nftData);
      localStorage.setItem("nftCollection", JSON.stringify(existingNFTs));

      const retrievedData = localStorage.getItem("nftCollection");

      alert("새로운 NFT가 추가되었습니다!");
      console.log(`저장된 데이터: ${retrievedData}`);
    } else {
      throw new Error("File upload failed");
    }
  };

  const handleSubmit = async (e) => {
    if (!selectedFile) {
      alert("파일을 선택해주세요.");
      return;
    }

    e.preventDefault();
    const formData = new FormData(e.target);
    setTitle(formData.get("title"));
    setDescription(formData.get("description"));
    setMediaType(formData.get("mediaType"));
    setPrice(formData.get("price"));

    await saveNFT(); // 저장된 파일을 업로드
  };

  useEffect(() => {
    document.documentElement.classList.add("dark");
  }, []);

  const handleChange = () => {
    const fileUploader = document.querySelector("#input-file");
    const getFile = fileUploader.files;
    if (getFile.length !== 0) {
      const uploadedFile = getFile[0];
      readFile(uploadedFile);
      setSelectedFile(uploadedFile);
    }
  };

  const readFile = async (uploadedFile) => {
    if (uploadedFile) {
      const reader = new FileReader();

      reader.onload = () => {
        const parent = document.querySelector(".preview-box");
        parent.innerHTML = `<img class="preview-content" src=${reader.result} />`;
        const base64Data = reader.result.split(",")[1];
        setGBase64Data(base64Data); // 상태를 업데이트
        console.log(base64Data);
      };

      reader.readAsDataURL(uploadedFile);
    }
  };
  return (
    <>
      <Navbar />

      <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
              Upload Your Work
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/index">K-Sori</Link>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Create Item
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container">
          <div className="grid md:grid-cols-12 gap-[30px]">
            <div className="lg:col-span-3 md:col-span-4">
              <div className="overflow-hidden rounded-md shadow dark:shadow-gray-800 sticky top-20">
                <div className="py-10 bg-[url('../../assets/images/blog/05.jpg')] bg-center bg-no-repeat"></div>

                <div className="relative text-center -mt-10 p-6 pt-0">
                  <img
                    src={image}
                    className="bg-white dark:bg-slate-900 h-20 w-20 rounded-full shadow-md dark:shadow-gray-800 mx-auto p-1"
                    alt=""
                  />

                  <div className="mt-3">
                    <Link
                      to="/creator-profile"
                      className="font-semibold block hover:text-yellow-500"
                    >
                      고백월드
                    </Link>
                    <span className="text-slate-400 text-sm block mt-1">
                      @goback
                    </span>
                  </div>

                  <ul
                    className="list-none sidebar-nav mb-0 mt-3"
                    id="navmenu-nav"
                  >
                    <li className="navbar-item account-menu text-[16px]">
                      <Link
                        to="/creator-profile"
                        className="navbar-link text-slate-400 flex items-center py-2 rounded"
                      >
                        <span className="me-2 mb-0">
                          <i className="uil uil-dashboard"></i>
                        </span>
                        <h6 className="mb-0 font-medium">프로필</h6>
                      </Link>
                    </li>

                    <li className="navbar-item account-menu text-[16px] active">
                      <Link
                        to="/upload-work"
                        className="navbar-link text-slate-400 flex items-center py-2 rounded"
                      >
                        <span className="me-2 mb-0">
                          <i className="uil uil-browser"></i>
                        </span>
                        <h6 className="mb-0 font-medium">NFT 등록</h6>
                      </Link>
                    </li>

                    <li className="navbar-item account-menu text-[16px]">
                      <Link
                        to="/creator-profile-edit"
                        className="navbar-link text-slate-400 flex items-center py-2 rounded"
                      >
                        <span className="me-2 mb-0">
                          <i className="uil uil-setting"></i>
                        </span>
                        <h6 className="mb-0 font-medium">설정</h6>
                      </Link>
                    </li>

                    <li className="navbar-item account-menu text-[16px]">
                      <Link
                        to="/lock-screen"
                        className="navbar-link text-slate-400 flex items-center py-2 rounded"
                      >
                        <span className="me-2 mb-0">
                          <i className="uil uil-sign-in-alt"></i>
                        </span>
                        <h6 className="mb-0 font-medium">로그아웃</h6>
                      </Link>
                    </li>
                    <li className="navbar-item account-menu text-[16px]">
                      <Link
                        to="/adminpage"
                        className="navbar-link text-slate-400 flex items-center py-2 rounded"
                      >
                        <span className="me-2 mb-0">
                          <i className="uil uil-sign-in-alt"></i>
                        </span>
                        <h6 className="mb-0 font-medium">Admin(임시)</h6>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="lg:col-span-9 md:col-span-8">
              <div className="lg:flex p-6 bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800">
                <div className="lg:w-1/3 md:w-full">
                  <p className="font-semibold mb-6">
                    아래, "업로드" 버튼을 클릭하여 작품을 업로드하세요.
                  </p>
                  <div className="preview-box flex justify-center rounded-md shadow dark:shadow-gray-800 overflow-hidden bg-gray-50 dark:bg-slate-800 text-slate-400 p-2 text-center small">
                    JPG, PNG, MP4. 최대 파일 사이즈 : 100MB.
                  </div>
                  <input
                    type="file"
                    id="input-file"
                    name="input-file"
                    accept="image/*"
                    hidden
                    onChange={() => handleChange()}
                  />
                  <label
                    className="btn-upload btn bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white rounded-full w-full mt-6 cursor-pointer"
                    htmlFor="input-file"
                  >
                    업로드
                  </label>
                </div>

                <div className="lg:w-2/3 md:w-full mt-8 lg:mt-0 lg:ms-6">
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12">
                        <label className="font-semibold">
                          타이틀 <span className="text-red-600">*</span>
                        </label>
                        <input
                          name="title"
                          type="text"
                          className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-yellow-500 focus:ring-0 mt-2"
                          placeholder="타이틀 :"
                          value={gTitle || ""} // 상태를 직접 연결
                          onChange={(e) => setTitle(e.target.value)} // 상태 업데이트
                        />
                      </div>
                      <div className="col-span-12">
                        <label className="font-semibold">설명 :</label>
                        <textarea
                          name="description"
                          className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-2xl outline-none border border-gray-200 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-yellow-500 focus:ring-0 mt-2"
                          placeholder="설명 :"
                          value={gDescription || ""} // 상태를 직접 연결
                          onChange={(e) => setDescription(e.target.value)} // 상태 업데이트
                        ></textarea>
                      </div>
                      <div className="md:col-span-6 col-span-12">
                        <label className="font-semibold">컬렉션 타입</label>
                        <select
                          name="mediaType"
                          className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-yellow-500 focus:ring-0 mt-2"
                          value={gMediaType || ""} // 상태를 직접 연결
                          onChange={(e) => setMediaType(e.target.value)} // 상태 업데이트
                        >
                          <option>화보</option>
                          <option>공연</option>
                          <option>브이로그</option>
                          <option>GIF</option>
                          <option>비하인드</option>
                        </select>
                      </div>
                      <div className="md:col-span-6 col-span-12">
                        <label className="font-semibold">가격</label>
                        <input
                          name="price"
                          type="number"
                          className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-yellow-500 focus:ring-0 mt-2"
                          placeholder="0.004ETH"
                          value={gPrice || ""} // 상태를 직접 연결
                          onChange={(e) => setPrice(e.target.value)} // 상태 업데이트
                        />
                      </div>
                      <div className="md:col-span-6 col-span-12">
                        <label className="font-semibold dark:shadow-gray-800 overflow-hidden bg-gray-50 dark:bg-slate-800 text-slate-400">
                          시작 시간 :
                        </label>
                        <input
                          name="startTime"
                          type="text"
                          className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-gray-500 rounded-full outline-none border border-gray-200 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-yellow-500 focus:ring-0 mt-2"
                          placeholder="Select date :(준비 중)"
                        />
                      </div>
                      <div className="md:col-span-6 col-span-12">
                        <label className="font-semibold dark:shadow-gray-800 overflow-hidden bg-gray-50 dark:bg-slate-800 text-slate-400">
                          종료 시간 :
                        </label>
                        <input
                          name="endTime"
                          type="text"
                          className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-gray-500 rounded-full outline-none border border-gray-200 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-yellow-500 focus:ring-0 mt-2"
                          placeholder="Select date :(준비 중)"
                        />
                      </div>
                      <div className="col-span-12">
                        <button
                          type="submit"
                          className="btn bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white rounded-full"
                        >
                          NFT 생성
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Switcher />
    </>
  );
}
