import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import image from '../../assets/images/items/3.gif';
import image1 from '../../assets/images/avatar/1.jpg';
import image2 from '../../assets/images/avatar/1.jpg';
import image3 from '../../assets/images/items/2.gif';
import image4 from '../../assets/images/items/1.jpg';
import image5 from '../../assets/images/items/2.jpg';
import image6 from '../../assets/images/items/1.gif';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Switcher from '../../components/switcher';

export default function ItemDetail() {

    const [activeIndex, setIndex] = useState(0);

    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    return (
        <>
            <Navbar />
            <section className="relative pt-28 md:pb-24 pb-16">
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-5">
                            <img src={image} className="rounded-md shadow dark:shadow-gray-700" alt="" />

                            <div className="bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-[30px] p-6">
                                <div>
                                    <span className="font-medium text-slate-400 block mb-1">Contract Address</span>
                                    <Link to="#" className="font-medium text-yellow-500 underline block">1fsvtgju51ntgeryimghf6ty7o9n3r3er246</Link>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Token ID</span>
                                    <span className="font-medium block">458342529342930944</span>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Blockchain</span>
                                    <span className="font-medium block">ETH</span>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Deposit & Withdraw</span>
                                    <span className="font-medium block">Unsupported</span>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-7 lg:ms-8">
                            <h5 className="md:text-2xl text-xl font-semibold">새청바지's 1st NFT #0001</h5>

                            <span className="font-medium text-slate-400 block mt-2">From this collection: <Link to="/creator-profile" className="text-yellow-500">@June</Link></span>

                            <p className="text-slate-400 mt-4">🎵 새청바지의 독특한 음악세계에 오신 것을 환영합니다!</p>
                            <p className="text-slate-400 mt-4">🎵🎵 안녕하세요, 저는 K-Sori에서 활동 중인 '새청바지'이라고 해요. 음악을 사랑하고, 그 사랑을 여러분과 함께 나누고 싶어요. 지금 제가 여러분께 선보이는 이 NFT는 단순한 작품이 아닌, 제 영혼과 열정이 담긴 특별한 선물입니다.</p>                            
                            <p className="text-slate-400 mt-4">🎤 이번에 있었던 공연영상을 담았어요. 저의 최근 라이브 공연의 순간순간을 담은 이 영상은 여러분이 직접 공연장에 와서 저와 함께한 것처럼 느낄 수 있게 해줄 거예요. 그 감동과 열기를 놓치지 마세요!</p>
                            <p className="text-slate-400 mt-4">✍️ 싸인을 드려요. 이 NFT에는 제 진심이 담긴 싸인도 포함되어 있어요. 제 음악을 사랑해주시는 분들께 직접 드리는 작은 감사의 표시랍니다.</p> 

                            <div className="mt-4">
                                <span className="text-lg font-medium text-slate-400 block">판매가</span>
                                <span className="tmd:text-2xl text-xl font-semibold block mt-2"><i className="mdi mdi-ethereum"></i> 3.5 ETH = $ 4,659.75</span>
                            </div>

                            <div className="mt-6">
                                <Link to="#" data-modal-toggle="NftBid" className="btn rounded-full bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white"><i className="mdi mdi-gavel"></i> 입찰</Link>
                                <Link to="#" data-modal-toggle="NftBuynow" className="btn rounded-full bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white ms-1"><i className="mdi mdi-lightning-bolt"></i> 구입</Link>
                            </div>

                            <div className="md:flex p-6 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700 mt-6">
                                <div className="md:w-1/2">
                                    <div className="flex items-center">
                                        <div className="relative inline-block">
                                            <img src={image1} className="h-16 rounded-md" alt="" />
                                            <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                        </div>

                                        <div className="ms-3">
                                            <Link to="/creator-profile" className="font-semibold block hover:text-yellow-500">새청바지</Link>
                                            <span className="text-slate-400 text-[16px] block mt-1">Creator</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="md:w-1/2 md:mt-0 mt-4">
                                    <div className="flex items-center">
                                        <div className="relative inline-block">
                                            <img src={image2} className="h-16 rounded-md" alt="" />
                                            <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                        </div>

                                        <div className="ms-3">
                                            <Link to="/creator-profile" className="font-semibold block hover:text-yellow-500">나는야 곰돌이</Link>
                                            <span className="text-slate-400 text-[16px] block mt-1">Owner</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 mt-8">
                                <ul className="md:w-fit w-full flex-wrap justify-center text-center p-3 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md" id="myTab" data-tabs-toggle="#StarterContent" role="tablist">
                                    <li role="presentation" className="md:inline-block block md:w-fit w-full">
                                        <button className={`px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndex === 0 ? 'text-white bg-yellow-500' : ''}`} id="wednesday-tab" data-tabs-target="#wednesday" type="button" role="tab" aria-controls="wednesday" aria-selected="true"
                                            onClick={() => setIndex(0)}
                                        >Bids</button>
                                    </li>
                                    <li role="presentation" className="md:inline-block block md:w-fit w-full">
                                        <button className={`px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndex === 1 ? 'text-white bg-yellow-500' : ''}`} id="thursday-tab" data-tabs-target="#thursday" type="button"
                                            onClick={() => setIndex(1)}
                                            role="tab" aria-controls="thursday" aria-selected="false">Activity</button>
                                    </li>
                                </ul>

                                <div id="StarterContent" className="mt-6">
                                    <div className="" id="wednesday" role="tabpanel" aria-labelledby="wednesday-tab">
                                        {
                                            activeIndex === 0 ? (
                                                <div className="grid grid-cols-1">
                                                    <div className="flex items-center">
                                                        <div className="relative inline-block">
                                                            <img src={image3} className="h-16 rounded-md" alt="" />
                                                            <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                                        </div>

                                                        <div className="ms-3">
                                                            <h6 className="font-semibold">2 ETH <span className="text-slate-400">by</span> <Link to="#" className="hover:text-yellow-500 duration-500 ease-in-out">0xe849fa28a...ea14</Link></h6>
                                                            <span className="text-slate-400 text-[16px]">6 hours ago</span>
                                                        </div>
                                                    </div>

                                                    <div className="flex items-center mt-4">
                                                        <div className="relative inline-block">
                                                            <img src={image4} className="h-16 rounded-md" alt="" />
                                                            <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                                        </div>

                                                        <div className="ms-3">
                                                            <h6 className="font-semibold">0.001 ETH <span className="text-slate-400">by</span> <Link to="#" className="hover:text-yellow-500 duration-500 ease-in-out">스토옥커</Link></h6>
                                                            <span className="text-slate-400 text-[16px]">6 hours ago</span>
                                                        </div>
                                                    </div>

                                                    <div className="flex items-center mt-4">
                                                        <div className="relative inline-block">
                                                            <img src={image5} className="h-16 rounded-md" alt="" />
                                                            <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                                        </div>

                                                        <div className="ms-3">
                                                            <h6 className="font-semibold">1.225 ETH <span className="text-slate-400">by</span> <Link to="#" className="hover:text-yellow-500 duration-500 ease-in-out">다판다곰</Link></h6>
                                                            <span className="text-slate-400 text-[16px]">6 hours ago</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) :
                                                (
                                                    <div className="grid grid-cols-1">
                                                        <div className="flex items-center">
                                                            <div className="relative inline-block">
                                                                <img src={image6} className="h-20 rounded-md" alt="" />
                                                                <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                                            </div>

                                                            <span className="content ms-3">
                                                                <Link to="#" className="hover:text-yellow-500 font-semibold block">안무 Collection</Link>
                                                                <span className="text-slate-400 block text-[16px] mt-1">Started Following <Link to="#" className="font-semibold hover:text-yellow-500">@다판다곰</Link></span>

                                                                <span className="text-slate-400 block text-[16px]">1 hours ago</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                        }

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
