import React, { useEffect } from 'react'
import Navbar from '../../components/navbar'
import AuctionsTwo from '../../components/auctions-two'
import image from '../../assets/images/blog/01.jpg';
import image1 from '../../assets/images/blog/03.jpg';
import image2 from '../../assets/images/blog/05.jpg';
import image3 from '../../assets/images/blog/07.jpg';
import image5 from '../../assets/images/blog/11.jpg';
import image6 from '../../assets/images/blog/02.jpg';
import image7 from '../../assets/images/blog/04.jpg';
import image8 from '../../assets/images/blog/06.jpg';
import image4 from '../../assets/images/blog/08.jpg';
// import image10 from '../../assets/images/blog/01.jpg';
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { Link } from 'react-router-dom';

export default function Auction() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    const auctionData = [
        {
            image: image,
            title: '앨범 Collection',
            subtext: '@CutieGirl',
            items: 25,
            avatar: image,
            date: 'December 29, 2023 6:0:0'
        },
        {
            image: image2,
            title: 'GIF Collection',
            subtext: '@버터시경',
            items: 25,
            avatar: image1,
            date: 'January 13, 2024 5:3:1'
        },
        {
            image: image3,
            title: '공연 Collection',
            subtext: '@NorseQueen',
            items: 25,
            avatar: image2,
            date: 'December 10, 2023 6:0:0'
        },
        {
            image: image4,
            title: '뮤지션 작품 Collection',
            subtext: '@Angel',
            items: 25,
            avatar: image5,
            date: 'February 14, 2024 1:2:1'
        },
        {
            image: image5,
            title: '비하인드 Collection',
            subtext: '@CutieGirl',
            items: 25,
            avatar: image,
            date: 'December 29, 2023 6:0:0'
        },
        {
            image: image6,
            title: 'GIF Collection',
            subtext: '@버터시경',
            items: 25,
            avatar: image7,
            date: 'April 08, 2024 5:1:40'
        },
        {
            image: image7,
            title: '노컷 Collection',
            subtext: '@NorseQueen',
            items: 25,
            avatar: image2,
            date: 'April 20, 2024 1:6:3'
        },
        {
            image: image8,
            title: '화보 Collection',
            subtext: '@Angel',
            items: 25,
            avatar: image5,
            date: 'May 30, 2024 1:5:2'
        },
    ]
    return (

        
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Live Auction</h3>

                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">K-Sori</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Auction</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative py-16">
                <AuctionsTwo data={auctionData} />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
